<template>
  <b-form @submit.prevent="onSubmit">



    <b-form-group
      label="Thumbnail:"
      label-for="input-0"
    >
      <img
        style="width: 200px"
        v-bind:src="form.Thumbnail"
      /><br /><br />
      <form action="#">
        <input
          type="file"
          @change="onFileSelected($event, post)"
        />
      </form>
    </b-form-group>








    <b-form-group
      label="Title:"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        placeholder="Enter Title"
        v-model="form.Title"
        required
      ></b-form-input>
    </b-form-group>

    <div class="row">
      <div class="col-md-6">
        <b-form-group
          label="Section:"
          label-for="input-3"
        >
          <b-form-select
            v-model="form.Section"
            :options="sectionItems"
            :value="form.Section"
            class="mt-3"
            @change="getSubsections()"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group
          label="Subsection:"
          label-for="input-4"
        >
          <b-form-select
            v-model="form.Subsection"
            :options="subsections"
            :value="form.Subsection"
            class="mt-3"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <b-form-group
          label="Educators:"
          label-for="input-educators"
          class="educators-section"
        >
          <div class="selected-educators mb-2" v-if="form.educators && form.educators.length">
            <span v-for="educator in form.educators" :key="educator.id" class="badge badge-info mr-2 mb-2">
              {{ educator.name }}
              <i class="fa fa-times ml-1" style="cursor: pointer" @click="removeEducator(educator)"></i>
            </span>
          </div>
          <b-form-select
            id="input-educators"
            v-model="selectedEducator"
            :options="availableEducators"
            value-field="id"
            text-field="name"
            @change="addEducator"
          >
            <template #first>
              <option value="">Select an educator to add...</option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group
          label="Included in Paths:"
          class="paths-section"
        >
          <div v-if="getPostPaths(form).length" class="included-paths">
            <span v-for="path in getPostPaths(form)" :key="path.id" class="badge badge-primary mr-2 mb-2">
              {{ path.title }}
            </span>
          </div>
          <div v-else class="text-muted">
            Not included in any paths
          </div>
        </b-form-group>
      </div>
    </div>

    <b-form-group
      label="Body:"
      label-for="input-2"
    >
      <b-form-textarea
        id="input-2"
        placeholder="Enter Body"
        v-model="form.Body"
        rows="5"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      label="Tagline:"
      label-for="input-5"
    >
      <b-form-input
        id="input-5"
        placeholder="Tagline"
        v-model="form.Tagline"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Document:"
      label-for="input-22"
    >
      <form action="#">
        <input
          id="input-22"
          type="file"
          @change="onDocumentSelected($event, post)"
        /><br />
        {{ form.Document }}
      </form>
    </b-form-group>

    <b-form-group
      label="Video URL:"
      label-for="input-6"
    >
      <b-form-input
        id="input-6"
        placeholder="Video URL"
        v-model="form.Video"
        @blur="getVimeoID(form.Video)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Vimeo ID:"
      label-for="input-7"
      v-if="form.VimeoID"
    >
      <div class="vimeo-id">{{ form.VimeoID }}</div>
    </b-form-group>

    <b-form-group label="Links:">
      <div class="links-container">
        <div v-for="(link, index) in form.links" :key="index" class="link-item mb-3">
          <div class="d-flex align-items-start">
            <div class="flex-grow-1">
              <b-form-input
                v-model="link.url"
                placeholder="Link URL"
                class="mb-2"
              ></b-form-input>
              <b-form-input
                v-model="link.label"
                placeholder="Link Label"
              ></b-form-input>
            </div>
            <button 
              class="btn btn-outline-danger ml-2" 
              @click="removeLink(index)"
              type="button"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <button 
          class="btn btn-outline-success" 
          @click="addNewLink"
          type="button"
        >
          <i class="fa fa-plus"></i> Add Link
        </button>
      </div>
    </b-form-group>

    <b-form-group label="Tags:" label-for="input-8">
      <div class="tags-container">
        <div class="tags-list">
          <span v-for="(tag, i) in form.Category" :key="i" class="badge badge-tag">
            #{{ tag }}
            <i class="fa fa-times" @click="removeTag(i)"></i>
          </span>
        </div>
        <div class="tag-input-container">
          <span class="hash-symbol">#</span>
          <input
            class="tag-input"
            placeholder="Add Tag"
            v-model="newTag"
            @keyup.enter="addTag"
          />
          <button class="btn btn-outline-primary btn-sm" @click="addTag" type="button">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </b-form-group>

    <div class="p-3">
      <b-button
        v-if="post && !documentUploading"
        type="submit"
        variant="primary"
        class="float-right"
      >Save</b-button>
      <div
        v-if="post && documentUploading"
        class="btn btn-secondary float-right"
      >
        Uploading...hang on...
      </div>

      <b-button
        type="submit"
        variant="primary"
        v-if="!post"
        class="float-right"
      >Add Post</b-button>
      <b-button
        type="button"
        v-on:click="hide()"
      >Cancel</b-button>
    </div>
  </b-form>
</template>

<script>
const axios = require("axios");
import { db, storage } from "@/db.js";
// import TagInput from "./TagInput";
import date from "date-and-time";
export default {
  components: {  },
  props: ["post", "mode"],
  watch: {
    post: {
      immediate: true,
      handler: "handlePostChange",
    },
  },
  data () {
    return {
      documentUploading: false,
      currentUser: null,
      sections: [],
      sectionItems: [],
      subsections: [],
      posts: [],
      newTag: "",
      form: {
        id: "",
        Body: "",
        Category: [],
        Section: "",
        Subsection: "",
        Tagline: "",
        Title: "",
        Video: "",
        Document: null,
        VimeoID: "",
        date: "",
        time: "",
        Thumbnail: "",
        links: [],
        educators: [],
      },
      selectedEducator: "",
      availableEducators: [],
    };
  },
  mounted () {
    const _this = this;
    this.$bind("sections", db.collection("sections").orderBy("section")).then(
      function () {
        _this.sections.forEach((s) => {
          //_this.sectionItems.push(s.section.toLowerCase());
          _this.sectionItems.push(s.section);
        });
      }
    );
    this.getSubsections();
    this.getEducators();
  },
  methods: {
    getDate(d){
return Date(d)
    },
    addTag () {
      this.form.Category.push(this.newTag);

      this.newTag = "";
    },

    removeTag (i) {
      this.$delete(this.form.Category, i);
    },

    handlePostChange () {
      if (!this.post) return;

      var publishedDate = this.post.PublishedDate
        ? new Date(this.post.PublishedDate)
        : new Date();
        
      // Convert existing numbered links to array format
      let links = [];
      if (this.post) {
        // Handle first link (no number)
        if (this.post.Link || this.post.LinkDescription) {
          links.push({
            url: this.post.Link || '',
            label: this.post.LinkDescription || ''
          });
        }
        
        // Handle links 2-5
        for (let i = 2; i <= 5; i++) {
          const linkKey = 'Link' + i;
          const descKey = 'LinkDescription' + i;
          if (this.post[linkKey] || this.post[descKey]) {
            links.push({
              url: this.post[linkKey] || '',
              label: this.post[descKey] || ''
            });
          }
        }
      }

      this.form = {
        ...JSON.parse(JSON.stringify(this.post)),
        time: `${publishedDate.getHours()}:${publishedDate.getMinutes()}`,
        date: `${publishedDate.getFullYear()}-${publishedDate.getMonth() < 10
            ? "0" + publishedDate.getMonth()
            : publishedDate.getMonth()
          }-${publishedDate.getDate()}`,
        educators: this.post.educators || [],
        links: links
      };
    },
    onTagsUpdated (categoryTags) {
      console.log("cat", this.form.Category);
      if (this.form.Category.length) {
        this.form.Category.splice(0, this.form.Category.length);
        this.form.Category.push(...categoryTags);
      }
    },
    onSubmit () {
      try {
        const now = new Date();
        let d = date.format(now, "YYYY-MM-DD hh:mm:ss");

        // Convert links array back to numbered format for backward compatibility
        let formData = {
          ...this.form,
          Link: null,
          LinkDescription: null,
          Link2: null,
          LinkDescription2: null,
          Link3: null,
          LinkDescription3: null,
          Link4: null,
          LinkDescription4: null,
          Link5: null,
          LinkDescription5: null,
        };

        this.form.links.forEach((link, index) => {
          const num = index === 0 ? '' : (index + 1);
          formData['Link' + num] = link.url || null;
          formData['LinkDescription' + num] = link.label || null;
        });

        if (this.mode == "edit") {
          //edit post
          db.collection("posts").doc(this.post.id).update({
            Body: formData.Body,
            Category: formData.Category,
            Section: formData.Section,
            Subsection: formData.Subsection,
            Tagline: formData.Tagline,
            Title: formData.Title,
            Video: formData.Video,
            VimeoID: formData.VimeoID,
            Document: formData.Document,
            Link: formData.Link,
            LinkDescription: formData.LinkDescription,
            Link2: formData.Link2,
            LinkDescription2: formData.LinkDescription2,
            Link3: formData.Link3,
            LinkDescription3: formData.LinkDescription3,
            Link4: formData.Link4,
            LinkDescription4: formData.LinkDescription4,
            Link5: formData.Link5,
            LinkDescription5: formData.LinkDescription5,
            Updated: Date.now(),
            educators: formData.educators,
          });

          this.$bvModal.hide("modal-edit-post");
        } else {
          //new post
          db.collection("posts").add({
            Body: formData.Body,
            Category: formData.Category,
            PublishedDate: d,
            NewOrder: d,
            Section: formData.Section,
            Subsection: formData.Subsection,
            Tagline: formData.Tagline,
            Title: formData.Title,
            Video: formData.Video,
            VimeoID: formData.VimeoID,
            Document: formData.Document,
            Thumbnail: formData.Thumbnail,
            Link: formData.Link,
            LinkDescription: formData.LinkDescription,
            Link2: formData.Link2,
            LinkDescription2: formData.LinkDescription2,
            Link3: formData.Link3,
            LinkDescription3: formData.LinkDescription3,
            Link4: formData.Link4,
            LinkDescription4: formData.LinkDescription4,
            Link5: formData.Link5,
            LinkDescription5: formData.LinkDescription5,
            educators: formData.educators,
          });

          this.$bvModal.hide("modal-new-post");
        }
      } catch (err) {
        alert("Error: Screenshot this for Glenn please - " + err);
      }
    },
    onDocumentSelected (event, post) {
      this.documentUploading = true;
      const _this = this;
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();

          this.form.Document = url;
          // // updating uploaded image url in doc
          // if (this.mode == "edit") {
          //   db.collection("posts").doc(post.id).update({
          //     Document: url,
          //   });
          // }

          // reseting file input
          //event.target.parentElement.reset();
          _this.documentUploading = false;
        });
    },
    onFileSelected (event, post) {
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();

          this.form.Thumbnail = url;
          // updating uploaded image url in doc
          if (this.mode == "edit") {
            db.collection("posts").doc(post.id).update({
              Thumbnail: url,
            });
          }

          // reseting file input
          event.target.parentElement.reset();
        });
    },
    getSubsections () {
      const _this = this;
      if (this.form.Section) {
        db.collection("sections")
          .where("section", "==", this.form.Section)
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => doc.data());

            _this.subsections = documents[0].subsections;
          });
      }
    },
    getVimeoID (video) {
      const _this = this;
      var config = {
        method: "get",
        url: "https://vimeo.com/api/oembed.json?url=" + video,
        headers: {
          Referer: "thedjsvault.com",
        },
      };

      axios(config)
        .then(function (response) {
          _this.form.VimeoID = response.data.video_id;
          console.log(response.data.video_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    hide () {
      this.$bvModal.hide("modal-new-post");
      this.$bvModal.hide("modal-edit-post");
    },
    getEducators() {
      this.$bind(
        "availableEducators",
        db.collection("educators")
          .where("active", "==", true)
          .orderBy("name", "asc")
      );
    },
    addEducator() {
      if (!this.selectedEducator) return;
      
      const educator = this.availableEducators.find(e => e.id === this.selectedEducator);
      if (educator && !this.form.educators.some(e => e.id === educator.id)) {
        this.form.educators.push(educator);
      }
      this.selectedEducator = "";
    },
    removeEducator(educator) {
      this.form.educators = this.form.educators.filter(e => e.id !== educator.id);
    },
    getPostPaths(post) {
      if (!post || !post.id) return [];
      return this.$parent.paths.filter(path => 
        path.posts && path.posts.some(p => p.id === post.id)
      );
    },
    addNewLink() {
      if (!this.form.links) {
        this.form.links = [];
      }
      this.form.links.push({ url: '', label: '' });
    },
    removeLink(index) {
      this.form.links.splice(index, 1);
    },
  },
};
</script>

<style>
/* Dark theme for modal and form */
.modal-content {
  background: #1c1c1c;
  border: 1px solid #444;
}

.modal-header {
  border-bottom: 1px solid #444;
  padding: 1rem 1.5rem;
}

.modal-footer {
  border-top: 1px solid #444;
  padding: 1rem 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

/* Form controls */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control,
.custom-select,
select.form-control,
.b-form-select {
  background: #2c2c2c !important;
  border: 1px solid #444 !important;
  color: #fff !important;
  padding: 0.75rem !important;
  border-radius: 4px;
  height: auto !important;
  font-size: 0.9rem;
}

.form-control:focus,
.custom-select:focus,
select.form-control:focus,
.b-form-select:focus {
  background: #2c2c2c !important;
  border-color: #666 !important;
  color: #fff !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}

.form-control::placeholder {
  color: #888;
}

textarea.form-control {
  min-height: 120px;
}

/* Remove margin from select elements */
.mt-3 {
  margin-top: 0 !important;
}

/* Custom select arrow */
.custom-select,
select.form-control,
.b-form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  padding-right: 2.5rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Dropdown options */
.custom-select option,
select.form-control option,
.b-form-select option {
  background: #2c2c2c;
  color: #fff;
  padding: 8px;
}

/* File inputs */
input[type="file"] {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0;
  margin-top: 0.5rem;
}

/* Image preview */
img {
  border-radius: 4px;
  border: 1px solid #444;
  margin-bottom: 1rem;
}

/* Section styling */
.educators-section,
.paths-section {
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 1rem;
}

.selected-educators,
.included-paths {
  margin-bottom: 0.75rem;
}

/* Badge styling */
.badge {
  font-size: 0.9rem;
  padding: 0.5em 0.8em;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: #2c2c2c;
  border: 1px solid #444;
  font-weight: normal;
}

.badge-info {
  background: #2c3e50;
  border-color: #34495e;
}

.badge-primary {
  background: #2c3e50;
  border-color: #34495e;
}

.badge i {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-left: 0.5rem;
}

.badge i:hover {
  opacity: 1;
}

.text-muted {
  color: #888 !important;
}

/* Spacing */
.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: 1.5rem;
}

.col-md-6 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

hr {
  border-color: #444;
  margin: 2rem 0;
  opacity: 0.5;
}

/* VimeoID display */
.vimeo-id {
  color: #888;
  font-size: 0.9rem;
  padding: 0.5rem;
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
}

/* Modal footer buttons */
.modal-footer .btn {
  min-width: 100px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.btn-secondary {
  background: #666;
  border: none;
  color: #fff;
}

.btn-secondary:hover {
  background: #777;
}

.btn-primary {
  background: #3498db;
  border: none;
}

.btn-primary:hover {
  background: #2980b9;
}

/* Modal footer spacing */
.modal-footer {
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.modal-footer > * {
  margin: 0;
}

.links-container {
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 1rem;
}

.link-item {
  background: #1c1c1c;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 1rem;
}

.link-item .btn-outline-danger {
  padding: 0.5rem;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.btn-outline-success {
  color: #2ecc71;
  border-color: #2ecc71;
  background: transparent;
  transition: all 0.2s;
}

.btn-outline-success:hover {
  background: #2ecc71;
  color: #fff;
}

.tags-container {
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 1rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 1rem;
}

.badge-tag {
  background: #1c1c1c;
  border: 1px solid #444;
  color: #fff;
  font-size: 0.9rem;
  padding: 6px 12px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.badge-tag i {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.badge-tag i:hover {
  opacity: 1;
}

.tag-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hash-symbol {
  color: #3498db;
  font-size: 1.1rem;
}

.tag-input {
  flex: 1;
  background: #1c1c1c;
  border: 1px solid #444;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.tag-input:focus {
  outline: none;
  border-color: #3498db;
}

.tag-input::placeholder {
  color: #888;
}
</style>