<template>
  <div class="post-actions">
    <div class="stat-item">
      <i class="fa fa-eye" :class="{ 'viewed': isViewed }"></i>
      <span>{{ post.Viewed ? post.Viewed.length : 0 }}</span>
    </div>

    <div class="stat-item">
      <i 
        class="fa fa-heart" 
        :class="{ 'liked': isLiked }"
        @click.stop="handleLikeClick"
      ></i>
      <span>{{ post.favorites ? post.favorites.length : 0 }}</span>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
const _ = require("lodash");

export default {
  props: {
    post: {
      type: Object,
      required: true
    },
    hideUnfavorited: Boolean,
    showViewedCheck: Boolean
  },
  data: function () {
    return {
      favorited: false,
      viewed: false //did person view this
    };
  },
  computed: {
    currentUserId() {
      return this.$store?.state?.currentUser?.uid || null;
    },
    isViewed() {
      return this.post?.Viewed?.includes(this.currentUserId) || false;
    },
    isLiked() {
      return this.post?.favorites?.includes(this.currentUserId) || false;
    },
    postId() {
      return this.$route.params.postId;
    }
  },
  methods: {
    handleLikeClick() {
      console.log('Heart clicked');
      console.log('Current user ID:', this.currentUserId);
      console.log('Post ID:', this.postId);
      console.log('Post object:', this.post);
      
      if (!this.currentUserId) {
        console.warn('User not logged in');
        return;
      }
      
      if (!this.post?.id) {
        console.error('No post ID available in post object');
        return;
      }
      
      if (this.isLiked) {
        console.log('Removing from favorites');
        this.removeFromFavorites();
      } else {
        console.log('Adding to favorites');
        this.addToFavorites();
      }
    },
    async addToFavorites() {
      if (!this.currentUserId || !this.post?.id) return;
      
      try {
        const docRef = db.collection("posts").doc(this.post.id);
        const doc = await docRef.get();
        
        if (!doc.exists) {
          console.error('Post document does not exist');
          return;
        }
        
        const currentFavorites = doc.data().favorites || [];
        console.log('Current favorites:', currentFavorites);
        
        if (!currentFavorites.includes(this.currentUserId)) {
          const newFavorites = [...currentFavorites, this.currentUserId];
          console.log('New favorites:', newFavorites);
          
          await docRef.update({
            favorites: newFavorites
          });
          
          this.$set(this.post, 'favorites', newFavorites);
        }
      } catch (error) {
        console.error('Error adding favorite:', error);
      }
    },
    async removeFromFavorites() {
      if (!this.currentUserId || !this.post?.id) return;
      
      try {
        const docRef = db.collection("posts").doc(this.post.id);
        const doc = await docRef.get();
        
        if (!doc.exists) {
          console.error('Post document does not exist');
          return;
        }
        
        const currentFavorites = doc.data().favorites || [];
        console.log('Current favorites:', currentFavorites);
        
        const updatedFavorites = currentFavorites.filter(id => id !== this.currentUserId);
        console.log('Updated favorites:', updatedFavorites);
        
        await docRef.update({
          favorites: updatedFavorites
        });
        
        this.$set(this.post, 'favorites', updatedFavorites);
      } catch (error) {
        console.error('Error removing favorite:', error);
      }
    }
  }
};
</script>

<style>
.post-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}

.stat-item i {
  font-size: 16px;
  transition: transform 0.2s ease, color 0.2s ease;
}

.stat-item i.fa-heart {
  cursor: pointer;
}

.stat-item i.fa-heart:hover {
  transform: scale(1.1);
}

.viewed {
  color: #28a745;
}

.liked {
  color: #ff4757;
  transform: scale(1.1);
}
</style>