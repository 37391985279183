<template>
  <div v-if="events && events.length">
    <h2 class="section-title">Upcoming Events</h2>

    <div class="video-grid">
      <div v-for="(event, i) in events" :key="i" class="event-item">
        <div class="event-card">
          <div class="event-image-container">
            <img
              :src="event.image || '/images/event-placeholder.jpg'"
              class="event-image"
              :alt="event.title"
            />
            <div class="event-date-badge">
              <span class="month">{{getMonth(event.date)}}</span>
              <span class="day">{{getDay(event.date)}}</span>
            </div>
            <div class="event-overlay">
              <div class="event-title">{{ event.title }}</div>
              <div class="event-description">{{ event.description }}</div>
              <a v-if="event.link" :href="event.link" target="_blank" class="event-link">
                <i class="fab fa-facebook"></i> View on Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import moment from "moment";

export default {
  data() {
    return {
      events: [],
    };
  },
  mounted() {
    const d = new Date();
    this.$bind(
      "events",
      db.collection("events2")
        .where('active', '==', true)
        .where('date', '>=', d)
        .orderBy("date")
        .limit(4)
    );
  },
  methods: {
    getMonth(d) {
      if (!d) return '';
      // Handle Firestore timestamp
      const date = d.toDate ? d.toDate() : new Date(d);
      return moment(date).format("MMM");
    },
    getDay(d) {
      if (!d) return '';
      // Handle Firestore timestamp
      const date = d.toDate ? d.toDate() : new Date(d);
      return moment(date).format("D");
    },
    getTime(d) {
      if (!d) return '';
      // Handle Firestore timestamp
      const date = d.toDate ? d.toDate() : new Date(d);
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
  }
};
</script>

<style scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}

.event-item {
  position: relative;
  transition: transform 0.3s ease;
  min-width: 0; /* Ensures content doesn't overflow */
}

.event-item:hover {
  transform: translateY(-5px);
}

.event-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: #2a2a2a;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.event-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-date-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  color: #151515;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: center;
  min-width: 50px;
  font-weight: bold;
  z-index: 2;
}

.event-date-badge .month {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
}

.event-date-badge .day {
  display: block;
  font-size: 20px;
  line-height: 1;
  margin-top: 2px;
}

.event-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  padding: 20px;
  transform: translateY(calc(100% - 60px));
  transition: transform 0.3s ease;
}

.event-item:hover .event-overlay {
  transform: translateY(0);
}

.event-title {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.event-link {
  display: inline-block;
  color: white;
  background-color: var(--iq-primary);
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.event-link:hover {
  background-color: var(--iq-primary-hover);
  text-decoration: none;
  color: white;
}

.section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-transform: capitalize;
}

@media (max-width: 1600px) {
  .video-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1400px) {
  .video-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}
</style>