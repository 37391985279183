<template>
  <div class="documents-page">
    <div class="container">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">Documents</h1>
      </div>

      <!-- Documents Grid -->
      <div class="documents-grid">
        <section 
          v-for="subsection in subsections" 
          :key="subsection"
          class="document-section"
        >
          <div class="document-container">
            <h2 class="section-title">{{ capitalizeWords(subsection) }}</h2>
            
            <div class="documents-list">
              <div 
                v-for="doc in getDocumentsBySubsection(subsection)" 
                :key="doc.id"
                class="document-item"
              >
                <div class="document-info">
                  <span class="document-title">{{ doc.Title }}</span>
                  <a 
                    :href="doc.Document" 
                    target="_blank"
                    class="download-button"
                    title="Download Document"
                  >
                    <i class="fa fa-download"></i>
                    <span class="download-text">Download</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import { orderBy } from 'lodash';

export default {
  data() {
    return {
      documents: null,
      section: null,
      subsections: null,
    };
  },
  mounted() {
    this.getDocuments();
    this.getSection();
  },
  methods: {
    getDocuments(subsection) {
      const _this = this;
      this.$bind(
        "documents",
        db
          .collection("posts")
          .where("Section", "==", "docs")
          
      ).then(() => {});
    },
    getDocumentsBySubsection(subsection) {
      if (!this.documents) return [];
      let docs = this.documents.filter((doc) => doc.Subsection == subsection);
      return orderBy(docs, item => item.Title?.toLowerCase() || '', ['asc']);
    },

    getSection() {
      const _this = this;
      this.$bind(
        "section",
        db.collection("sections").where("section", "==", "docs")
      ).then(() => {
        _this.subsections = _this.section[0].subsections;
      });
    },
    capitalizeWords(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>

<style scoped>
.documents-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

/* Documents Grid */
.documents-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.document-section {
  margin-bottom: 20px;
}

.document-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 30px;
  transition: all 0.3s ease;
}

.document-container:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.15);
}

.section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.documents-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.document-item {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.document-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateX(4px);
}

.document-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.document-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.download-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #eb9e34;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 8px 16px;
  border-radius: 6px;
  background: rgba(235, 158, 52, 0.1);
  transition: all 0.2s ease;
}

.download-button:hover {
  background: rgba(235, 158, 52, 0.2);
  color: #eb9e34;
}

.download-button i {
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .documents-page {
    padding: 60px 0;
  }

  .container {
    padding: 0 15px;
  }

  .page-header {
    margin-bottom: 30px;
  }

  .page-title {
    font-size: 2rem;
  }

  .document-container {
    padding: 20px;
  }

  .section-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .document-info {
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .download-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .documents-page {
    padding: 40px 0;
  }

  .page-title {
    font-size: 1.8rem;
  }
}
</style>