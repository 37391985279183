<template>
  <div class="educator-form">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-md-4">
          <div class="image-upload-container">
            <img 
              :src="imagePreview || form.image || '/images/placeholder.png'" 
              class="preview-image"
              alt="Educator profile"
            >
            <div class="image-upload-overlay">
              <label class="btn btn-outline-light mb-0">
                <i class="fa fa-upload"></i> Upload Image
                <input 
                  type="file" 
                  accept="image/*"
                  @change="handleImageUpload" 
                  class="d-none"
                >
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <b-form-group label="Name">
            <b-form-input
              v-model="form.name"
              required
              placeholder="Enter educator's name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Title">
            <b-form-input
              v-model="form.title"
              placeholder="Enter educator's title or role"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Bio">
        <b-form-textarea
          v-model="form.bio"
          placeholder="Enter educator's bio"
          rows="4"
        ></b-form-textarea>
      </b-form-group>

      <div class="form-row">
        <div class="col-md-6">
          <b-form-group label="Website">
            <b-form-input
              v-model="form.website"
              type="url"
              placeholder="https://example.com"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Status">
            <div class="checkbox-wrapper">
              <input type="checkbox" v-model="form.active" id="active-status" class="status-checkbox">
              <label for="active-status" class="status-label">
                Active
              </label>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="social-links">
        <h5>Social Media Links</h5>
        <div class="form-row">
          <div class="col-md-4">
            <b-form-group label="Facebook">
              <b-form-input
                v-model="form.facebook"
                placeholder="Facebook profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Instagram">
              <b-form-input
                v-model="form.instagram"
                placeholder="Instagram profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Twitter">
              <b-form-input
                v-model="form.twitter"
                placeholder="Twitter profile URL"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { storage } from "@/db.js";

export default {
  name: "EducatorForm",
  props: {
    educator: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      required: true,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      form: {
        name: "",
        title: "",
        bio: "",
        website: "",
        facebook: "",
        instagram: "",
        twitter: "",
        image: "",
        active: true
      },
      imagePreview: null,
      imageFile: null
    };
  },
  watch: {
    educator: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form = { ...newVal };
        }
      }
    }
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.imageFile = file;
      this.imagePreview = URL.createObjectURL(file);
    },
    async uploadImage() {
      if (!this.imageFile) return this.form.image;

      const timestamp = Date.now();
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`educators/${timestamp}_${this.imageFile.name}`);

      try {
        const snapshot = await imageRef.put(this.imageFile);
        const downloadURL = await snapshot.ref.getDownloadURL();
        return downloadURL;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },
    async onSubmit() {
      try {
        if (this.imageFile) {
          this.form.image = await this.uploadImage();
        }

        this.$emit("submit", this.form);
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("Failed to save educator. Please try again.");
      }
    }
  }
};
</script>

<style>
.educator-form {
  color: #fff;
}

.image-upload-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
  background: #1c1c1c;
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.image-upload-container:hover .image-upload-overlay {
  opacity: 1;
}

.social-links {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #444;
}

.social-links h5 {
  color: #fff;
  margin-bottom: 1rem;
}

/* Form Control Styles */
.form-control {
  background: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
}

.form-control:focus {
  background: #2c2c2c;
  border-color: #3498db;
  color: #fff;
  box-shadow: none;
}

.form-control::placeholder {
  color: #666;
}

.custom-switch .custom-control-label::before {
  background-color: #666;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
}

label {
  color: #fff;
  font-weight: 500;
}

@media (max-width: 767px) {
  .image-upload-container {
    padding-top: 75%;
  }
  
  .form-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-checkbox {
  width: 16px;
  height: 16px;
  margin: 0;
}

.status-label {
  color: #fff;
  user-select: none;
  margin: 0;
  cursor: pointer;
}
</style> 