<template>
  <div>

    <h2
      class="text-danger text-center"
      v-if="!show && this.$store.state.currentUser.membershipLevel == 'lite'"
    >
      <br /><br />Sorry this content is only for Standard and Annual Members<br /><br />
      <a class="text-warning" target="_blank" href="https://www.thedjsvault.com/join">Click here to upgrade!</a>
    </h2>

    <h2
      class="text-danger text-center"
      v-if="!show && this.$store.state.currentUser.membershipLevel == 'standard'"
    >
      <br /><br />Sorry this content is only for Annual Members<br /><br />
      <a class="text-warning" target="_blank" href="https://www.thedjsvault.com/vaultOTO">Click here to upgrade!</a>


      
    </h2>

    <section
      class="container-fluid"
      v-if="show"
    >

      <div class="row">
        <div class="col-lg-9">
          <div class="video-container">
            <div id="vimeo"></div>
          </div>

          <div class="post-meta">
            <h1 class="post-title">{{ post.Title }}</h1>
            <post-actions :post="post"></post-actions>
          </div>

          <div class="post-body" v-if="post.Body">
            {{ post.Body }}
          </div>

          <div class="document-container" v-if="post.Document && post.Document.length > 0">
            <embed :src="post.Document" style="width: 100%; height: 100vh" />
          </div>

          <div class="hashtags" v-if="post.Category && post.Category.length">
            <span v-for="(c, i) in post.Category" :key="i" class="hashtag" @click="searchTag(c)">
              #{{ c }}
            </span>
          </div>

          <div class="post-links" v-if="post.Link || post.Link2 || post.Link3 || post.Link4 || post.Link5">
            <a v-if="post.Link" :href="post.Link" target="_blank">
              <i class="fa fa-link"></i>
              {{ post.LinkDescription }}
            </a>
            <a v-if="post.Link2" :href="post.Link2" target="_blank">
              <i class="fa fa-link"></i>
              {{ post.LinkDescription2 }}
            </a>
            <a v-if="post.Link3" :href="post.Link3" target="_blank">
              <i class="fa fa-link"></i>
              {{ post.LinkDescription3 }}
            </a>
            <a v-if="post.Link4" :href="post.Link4" target="_blank">
              <i class="fa fa-link"></i>
              {{ post.LinkDescription4 }}
            </a>
            <a v-if="post.Link5" :href="post.Link5" target="_blank">
              <i class="fa fa-link"></i>
              {{ post.LinkDescription5 }}
            </a>
          </div>

          <Comments
            :post="post"
            :postId="this.$route.params.postId"
            class="pt-4"
          ></Comments>
        </div>
        <div class="col-lg-3">

          <a
            v-if="this.ads[0]"
            :href="this.ads[0].link"
            target="_blank"
          >
            <img
              :src="this.ads[0].image"
              class="img-fluid rounded mb-4"
              alt=""
            /></a>

          <div class="related-section">
            <h4>Related Videos</h4>
            <vertical :posts="relatedPosts.slice(0, 5)"></vertical>
          </div>
        </div>

      </div>

    </section>

    <!-- <slider :title="'Related Content'" :posts="relatedPosts" v-if="relatedPosts"></slider> -->

  </div>
</template>

<script>
import { db } from "@/db.js";

import PostActions from "@/components/PostActions.vue";
import Vertical from "@/components/Vertical.vue";

import Comments from '../components/Comments.vue';


export default {
  components: { Comments, PostActions, Vertical },

  data: function () {
    return {
      post: null,
      postId: this.$route.params.postId,
      section: "",
      subsection: "",
      relatedPosts: [],
      show: false,
      viewed: false,
      ads: []
    };
  },
  computed: {
    isPostLoaded() {
      return this.post && typeof this.post === 'object' && this.post.id;
    }
  },
  watch: {


    '$store.state.currentUser.membershipLevel': {

      immediate: true,
      handler: "getPost",
    },

    post () {
      var options = {
        url: this.post.Video,
        width: 1024,
        responsive: true,
      };

      var videoPlayer = new Vimeo.Player("vimeo", options);
    },
  },
  mounted () {
    this.getPost();

    this.$bind(
      "ads",
      db
        .collection("ads")

        // .orderBy("order", "desc")
        .limit(20)
    ).then(() => {

      let shuffled = this.ads.sort(() => Math.random() - 0.5)
      this.ads = shuffled;

    });
  },
  methods: {
    addToViewed () {
      console.log("POST", this.post);

      let currentViewed = [];
      if (this.post.Viewed) {
        currentViewed = this.post.Viewed;
      } else {
        console.log("no views")
      }

      currentViewed.push(this.$store.state.currentUser.uid);
      console.log("viewed", currentViewed);

      db.collection("posts")
        .doc(this.$route.params.postId)
        .update({
          Viewed: currentViewed,
        })
        .then(() => {
          this.viewed = true;
        });
    },
    searchTag (tag) {
      this.$router.push({ path: "/search", query: { tag: tag } });
    },
    async getPost() {
      try {
        const doc = await db.collection("posts").doc(this.postId).get();
        if (doc.exists) {
          this.post = { id: doc.id, ...doc.data() };
          this.section = this.post.Section;
          this.subsection = this.post.Subsection;
          this.getRelatedPosts();
          this.checkMembershipAccess();
        } else {
          console.error('Post not found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    },
    checkMembershipAccess() {
      const userLevel = this.$store.state.currentUser.membershipLevel;
      
      if (this.post.membershipLevel === "annual" && userLevel !== "annual") {
        this.show = false;
      } else if (
        this.post.membershipLevel === "standard" &&
        userLevel !== "standard" &&
        userLevel !== "annual"
      ) {
        this.show = false;
      } else {
        this.show = true;
        this.addToViewed();
      }
    },
    getRelatedPosts () {
      db.collection("posts")
        .where("Section", "==", this.section)
        .where("Subsection", "==", this.subsection)
        .get()
        .then((querySnapshot) => {
          // const documents = querySnapshot.docs.map((doc) => doc.data());

          querySnapshot.forEach((doc) => {
            let document = {};

            document = doc.data();
            document.id = doc.id;
            if (doc.id !== this.$route.params.postId) {
              this.relatedPosts.push(document);
            }
          });
        });
      //   .where("Section", "==", this.section)
      //  // .where("Subsection", "==", this.subsection)
      //  // .where("id", "!=", this.postId)
      //   //.orderBy("id", "desc")
      //   .get()
      //   .then((querySnapshot) => {
      //     const relatedPosts =  querySnapshot.docs.map(doc => doc.data())
      //     console.log("related", querySnapshot);
      //     this.relatedPosts = relatedPosts;
      //   })
      //   .then(() => this.slider(".favorites-slider"));
    },
    // slider(className) {
    //   jQuery(className).slick({
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 300,
    //     autoplay: false,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     // appendArrows: $('#sm-slick-arrow'),

    //     nextArrow:
    //       '<a href="#" class="slick-arrow slick-next"><i class= "fa fa-chevron-right"></i></a>',
    //     prevArrow:
    //       '<a href="#" class="slick-arrow slick-prev"><i class= "fa fa-chevron-left"></i></a>',
    //     responsive: [
    //       {
    //         breakpoint: 1200,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 3,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           // arrows: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   });
    // },

    onPostClick (post) {
      this.$router.push(`/post/${post.id}`);
    },
  },
};
</script>

<style scoped>
.post-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.post-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
  line-height: 1.3;
}

.container-fluid {
  padding: 5rem 2rem 2rem;
  max-width: 1600px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.video-container #vimeo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post-meta {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.post-body {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.document-container {
  margin: 1.5rem 0;
  border-radius: 8px;
  overflow: hidden;
}

.hashtags {
  margin: 1.5rem 0;
}

.hashtag {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin: 0 0.5rem 0.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hashtag:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.post-links {
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.post-links a {
  display: block;
  color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 0;
  transition: color 0.2s ease;
}

.post-links a:hover {
  color: #fff;
  text-decoration: none;
}

.post-links i {
  margin-right: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}

.related-section {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 8px;
}

.related-section h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #fff;
}

@media (max-width: 992px) {
  .container-fluid {
    padding: 5rem 1rem 1rem;
  }
  
  .post-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .container-fluid {
    padding: 4rem 0.75rem 1rem;
  }
  
  .post-title {
    font-size: 1.25rem;
  }
  
  .hashtag {
    font-size: 0.8125rem;
    padding: 0.2rem 0.5rem;
  }
}
</style>
