<template>
  <div>
     
    <div class="row" v-for="(post,i) in posts" :key="i"> 
      <div class="col-sm-12">
  
   
        <post-card :post="post"></post-card>
       
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from "./PostCard.vue";
export default {
  components: { PostCard },
  props: ["posts", "title"],
  data() {
    return {};
  },
};
</script>

<style>
.row {
  margin-bottom: 1.5rem;
}

.row:last-child {
  margin-bottom: 0;
}
</style>