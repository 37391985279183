<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-card">
        <!-- Logo -->
        <div class="logo-container">
          <img src="images/logo-full.png" alt="The DJs Vault" class="logo" />
        </div>

        <!-- Error Message -->
        <div v-if="loginError" class="error-message">
          {{ loginError }}
        </div>

        <!-- Login Form -->
        <div v-if="!reset" class="login-form">
          <div class="form-group">
            <input
              v-model="email"
              type="email"
              class="form-input"
              placeholder="Enter your email"
            />
          </div>
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-input"
              placeholder="Enter your password"
            />
          </div>
          <button class="login-button" @click="login">
            Login
          </button>
          <div class="legal-text">
            By logging into this site you agree to the 
            <a href="https://www.iubenda.com/privacy-policy/93415460/legal" target="_blank">Privacy Policy</a>, 
            <a href="https://www.iubenda.com/privacy-policy/93415460/cookie-policy" target="_blank">Cookie Policy</a> and 
            <a href="https://www.iubenda.com/terms-and-conditions/93415460" target="_blank">Terms and Conditions</a>
          </div>
          <div class="forgot-password">
            <a @click="forgotPW" class="text-danger">Forgot your password?</a>
          </div>
        </div>

        <!-- Password Reset Form -->
        <div v-if="reset" class="reset-form">
          <div class="reset-message">
            Please enter your email address below.
            <div class="reset-note">You must already be a subscriber of The DJ's Vault</div>
          </div>
          
          <div class="form-group">
            <input
              v-model="resetEmail"
              type="email"
              class="form-input"
              placeholder="Enter your email address"
            />
            <button class="reset-button" @click="resetPassword">
              Send Reset Link
            </button>
          </div>

          <div v-if="successMessage" class="success-message">
            We have sent a link to {{ resetEmail }} to reset your password.
          </div>
          
          <div v-if="failureMessage" class="warning-message">
            We were not able to match that email address to a registered user.
          </div>

          <div class="back-to-login" @click="reset = false">
            <i class="fa fa-angle-double-left"></i> Back To Login
          </div>
        </div>

        <!-- Help Text -->
        <div class="help-text">
          Have questions or need help? Contact us at 
          <a href="mailto:help@thedjsvault.com">help@thedjsvault.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
var firebaseui = require("firebaseui");

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());
import usersData from "/members.json";

export default {
  data () {
    return {
      reset: false,
      resetEmail: "",
      successMessage: false,
      failureMessage: false,
      email: "",
      password: "",
      originalUsers: [],
      loginError: null,
    };
  },
  mounted () {
    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }
    let _this = this;

    window.onerror = function (message, source, lineno, colno, error) {
      _this.loginError = "WINDOW ERROR: " + message + " - " + error;
      console.log("WINDOW ERROR: ", message + " - " + error);
    };

    this.originalUsers = usersData;
  },
  methods: {
    login () {
      this.loginError = null;

      const _this = this;
      try {
        firebase
          .auth()
          .fetchSignInMethodsForEmail(this.email)
          .then((result) => {

            if (result.length) {
              //User exists in auth list

              firebase
                .auth()
                .signInWithEmailAndPassword(
                  this.email.toString().trim(),
                  this.password
                )
                .then((userCredential) => {
                  // *********** Signed in *************
                  //////////////check Kartra and make sure the logged in user is active
                  var formdata = new FormData();
                  formdata.append("email", this.email.toString().trim());
                  var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                  };
                  fetch(
                    "https://app.cratehackers.com/version-test/api/1.1/wf/kartragetlead",
                    requestOptions
                  )
                    .then((response) => response.text())
                    .then((result) => {
                      let res = JSON.parse(result);
                      console.log(
                        "RESULT",
                        res.response.lead["_api_c2_lead_details.memberships"]
                      );

                      //check kartra memberships and make sure there is one that is active for The DJ's Vault
                      let memberships = res.response.lead[
                        "_api_c2_lead_details.memberships"
                      ].filter((membership) => {
                        return (
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Standard" &&
                            membership._api_c2_active == 1) ||
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Annual" &&
                            membership._api_c2_active == 1) ||
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Lite" &&
                            membership._api_c2_active == 1)
                        );
                      });

                      console.log("DJV Memberships", memberships);

                      if (memberships.length) {
                        //if memberships gt 0 then they have an active account
                        this.$router.push("/");
                      } else {
                        alert("It looks like you don't have an active account. If you believe this is an error please email help@thedjsvault.com")
                        this.$router.push("/login");
                      }
                    });
                  ///////////////End Kartra check
                })
                .catch((error) => {
                  //BAD PASSWORD
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  this.loginError = errorMessage + " " + this.email;
                  console.log("Error2: ", error);
                });
            } else {
              //BAD EMAIL or User Doesn't exist
              this.loginError = "User Doesn't Exist" + " " + this.email;
              console.log("Error3: ", "User Doesn't Exist");
            }
          });
      } catch (err) {
        this.loginError = "Error: " + err + " " + this.email;
        console.log("Error4: ", err);
      }
    },

    forgotPW () {
      this.reset = true;
    },

    resetPassword () {
      this.failureMessage = false;
      this.successMessage = false;
      const _this = this;

      var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: "https://app.thedjsvault.com",
        // handleCodeInApp: false
      };

      firebase
        .auth()
        .sendPasswordResetEmail(this.resetEmail, actionCodeSettings)
        .then(() => {
          //user exists
          _this.successMessage = true;
          // ..
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;

          //we need to check the user list that didn't import
          const originalUser = this.originalUsers.find(
            (element) => element.Email == this.resetEmail
          );
          if (originalUser) {
            this.addUser(originalUser);
          } else {
            console.log("NO");
            _this.failureMessage = true;
          }
          /////////////
        });
    },
    addUser (user) {
      const request = require("request");
      const _this = this;
      var options = {
        method: "POST",
        url:
          "https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/kartra",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lead: {
            first_name: user.FirstName,

            last_name: user.LastName,

            email: user.Email,

            phone: "",
          },
          action: "membership_granted",
          action_details: {
            membership: {
              level_name: user.Membership,
            },
          },
        }),
      };
      request(options, function (error, response) {
        if (error) {
          console.error("ERROR:" + user.Email, error);
        }
        console.log(response);
        //console.log(response.body);
      });
    },
  },
};
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #151515;
  padding: 20px;
}

.login-container {
  width: 100%;
  max-width: 420px;
}

.login-card {
  background: #1c1c1c;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.logo-container {
  text-align: center;
  margin-bottom: 32px;
}

.logo {
  max-width: 200px;
  height: auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: #2a2a2a;
  border: 2px solid #3a3a3a;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  border-color: var(--iq-primary);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--iq-primary-rgb), 0.2);
}

.login-button, .reset-button {
  width: 100%;
  height: 48px;
  background: #ff3b30;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.login-button:hover, .reset-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  background: #ff3b30;
}

.forgot-password {
  margin-top: 16px;
  text-align: center;
}

.forgot-password a {
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.forgot-password a.text-danger {
  color: #ff3b30 !important;
}

.forgot-password a:hover {
  opacity: 0.8;
}

.error-message {
  background: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 0.9rem;
}

.reset-message {
  text-align: center;
  margin-bottom: 24px;
  color: #fff;
}

.reset-note {
  color: var(--iq-primary);
  font-size: 0.9rem;
  margin-top: 8px;
}

.success-message {
  background: rgba(52, 199, 89, 0.1);
  color: #34c759;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 0.9rem;
}

.warning-message {
  background: rgba(255, 204, 0, 0.1);
  color: #ffcc00;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 0.9rem;
}

.legal-text {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #fff;
  font-size: 0.9rem;
}

.legal-text a {
  color: var(--iq-primary);
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.legal-text a:hover {
  opacity: 0.8;
}

.back-to-login {
  text-align: center;
  margin-top: 20px;
  color: #999;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.back-to-login:hover {
  color: #fff;
}

.back-to-login i {
  margin-right: 8px;
}

.help-text {
  text-align: center;
  margin-top: 24px;
  color: #999;
  font-size: 0.9rem;
}

.help-text a {
  color: #ff3b30;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.help-text a:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  .login-card {
    padding: 30px 20px;
  }
  
  .logo {
    max-width: 180px;
  }
}
</style>
