<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="Event Image" label-for="image-upload">
      <div class="image-upload-container">
        <img
          v-if="form.image"
          :src="form.image"
          class="preview-image"
        />
        <div v-else class="upload-placeholder">
          <i class="fas fa-cloud-upload-alt"></i>
          <span>Click to upload image</span>
        </div>
        <input
          id="image-upload"
          type="file"
          @change="onImageSelected($event)"
          class="custom-file-input"
          accept="image/*"
        />
      </div>
    </b-form-group>

    <b-form-group label="Event Title" label-for="input-title">
      <b-form-input
        id="input-title"
        placeholder="Enter event title"
        v-model="form.title"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Description" label-for="input-description">
      <b-form-textarea
        id="input-description"
        placeholder="Enter event description"
        v-model="form.description"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <div class="d-flex">
      <b-form-group label="Date" class="mr-3 flex-grow-1">
        <b-form-datepicker 
          v-model="form.date" 
          class="mb-2"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group label="Time" class="flex-grow-1">
        <b-form-timepicker 
          v-model="form.time" 
          class="mb-2"
        ></b-form-timepicker>
      </b-form-group>
    </div>

    <div class="p-3">
      <b-button
        @click="addEvent()"
        variant="primary"
        class="float-right"
      >Add</b-button>
      <b-button
        type="button"
        v-on:click="hide()"
      >Cancel</b-button>
    </div>
  </b-form>
</template>

<script>
const axios = require("axios");
import { db, storage } from "@/db.js";
import { Timestamp } from 'firebase'

import date from "date-and-time";
export default {
  components: {  },
  props: ["mode"],
 

  data () {
    return {
      documentUploading: false,
      currentUser: null,



      form: {
        id: "",
        image: "",
        title: "",
        description: "",
        date: null,
        time: null,
      },
    };
  },
  mounted () {
   
  },
  methods: {
   
   
    addEvent () {
      try {
        const eventDate = new Date(this.form.date);
        const [hours, minutes] = this.form.time.split(':');
        eventDate.setHours(parseInt(hours), parseInt(minutes));

        db.collection("events2").add({
          title: this.form.title,
          image: this.form.image,
          description: this.form.description,
          date: Timestamp.fromDate(eventDate),
          createdAt: Timestamp.fromDate(new Date()),
        });

        this.$bvModal.hide("modal-new-event");
      } catch (err) {
        alert("Error: Screenshot this for Glenn please - " + err);
      }
    },
   
    onImageSelected (event) {
      const file = event.target.files[0];
      storage
        .ref()
        .child(file.name)
        .put(file)
        .then(async (snapshot) => {
          //get uploaded file url
          const url = await snapshot.ref.getDownloadURL();


     this.form.image = url;

        
      
          // // updating uploaded image url in doc
          // if (this.mode == "edit") {
          //   db.collection("posts").doc(post.id).update({
          //     Thumbnail: url,
          //   });
          // }

          // reseting file input
          // event.target.parentElement.reset();
        });
    },
    getSubsections () {
      const _this = this;
      if (this.form.Section) {
        db.collection("sections")
          .where("section", "==", this.form.Section)
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => doc.data());

            _this.subsections = documents[0].subsections;
          });
      }
    },
    getVimeoID (video) {
      const _this = this;
      var config = {
        method: "get",
        url: "https://vimeo.com/api/oembed.json?url=" + video,
        headers: {
          Referer: "thedjsvault.com",
        },
      };

      axios(config)
        .then(function (response) {
          _this.form.VimeoID = response.data.video_id;
          console.log(response.data.video_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    hide () {
   
      this.$bvModal.hide("modal-new-event");
    },
  },
};
</script>

<style>
.image-upload-container {
  width: 200px;
  height: 80px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  cursor: pointer;
  margin-bottom: 1rem;
}

.upload-placeholder {
  text-align: center;
  color: #6c757d;
  font-size: 0.9rem;
}

.upload-placeholder i {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 4px;
}

.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-input::-webkit-file-upload-button {
  display: none;
}
</style>