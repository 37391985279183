<template>
  <div class="post-card">
    <div class="image-container" @click="go(post.id)">
      <img
        v-if="post.Thumbnail"
        :src="optimizedThumbnail"
        loading="lazy"
        class="card-img"
        :alt="post.Title"
      />
      <div v-else class="no-thumbnail">
        <img src="/images/vaultbgDoc.jpg" loading="lazy" class="card-img" :alt="post.Title">
      </div>
    </div>
    <div class="card-stats">
      <div class="stat-item">
        <i class="fa fa-eye" :class="{ 'viewed': isViewed }"></i>
        <span>{{ post.Viewed ? post.Viewed.length : 0 }}</span>
      </div>
      <div class="stat-item">
        <i 
          class="fa fa-heart" 
          :class="{ 'liked': isLiked }"
          @click.stop="toggleLike"
        ></i>
        <span>{{ post.favorites ? post.favorites.length : 0 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";

export default {
  props: ["post"],
  computed: {
    isViewed() {
      return this.post.Viewed && this.post.Viewed.includes(this.$store.state.currentUser.uid);
    },
    isLiked() {
      return this.post.favorites && this.post.favorites.includes(this.$store.state.currentUser.uid);
    },
    optimizedThumbnail() {
      if (!this.post.Thumbnail) return '';
      
      // If it's a Firebase Storage URL
      if (this.post.Thumbnail.includes('firebasestorage.googleapis.com')) {
        // Add width parameter for Firebase Storage
        return `${this.post.Thumbnail}&w=400`;
      }
      
      // If it's a Cloudinary URL
      if (this.post.Thumbnail.includes('cloudinary.com')) {
        // Add Cloudinary transformations
        return this.post.Thumbnail.replace('/upload/', '/upload/w_400,q_auto,f_auto/');
      }
      
      // For other URLs, return as is
      return this.post.Thumbnail;
    }
  },
  methods: {
    go(postId) {
      this.$router.push({ name: 'Post', params: { postId: postId } })
    },
    async toggleLike() {
      const userId = this.$store.state.currentUser.uid;
      const favorites = this.post.favorites || [];
      
      if (this.isLiked) {
        // Remove like
        const index = favorites.indexOf(userId);
        favorites.splice(index, 1);
      } else {
        // Add like
        favorites.push(userId);
      }

      // Update in Firebase
      await db.collection("posts").doc(this.post.id).update({
        favorites: favorites
      });
    }
  }
};
</script>

<style>
.post-card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background: #000;
}

.image-container {
  position: relative;
  aspect-ratio: 16/9;
  cursor: pointer;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-stats {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: white;
}

.stat-item i {
  font-size: 14px;
  transition: transform 0.2s ease, color 0.2s ease;
}

.fa-heart {
  cursor: pointer;
}

.fa-heart:hover {
  transform: scale(1.1);
}

.viewed {
  color: #28a745;
}

.liked {
  color: #ff4757;
  transform: scale(1.1);
}
</style>