<template>
  <div class="event-admin">
    <div class="row p-3">
      <div class="col-md-3">
        <button class="btn btn-danger" @click="showNewModal()">
          Add New Event
        </button>
      </div>
      <div class="col-md-3">
        <b-form-select
          v-model="timeFilter"
          :options="timeFilterOptions"
          class="form-control"
        ></b-form-select>
      </div>
      <div class="col-md-6">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
          class="form-control"
        ></b-form-input>
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        striped
        hover
        :items="filteredEvents"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        responsive
      >
        <template #cell(image)="row">
          <div class="event-image-container">
            <img 
              v-if="row.item.image" 
              :src="row.item.image" 
              class="event-thumbnail" 
              :alt="row.item.title"
            />
            <div v-else class="no-image">
              <i class="fas fa-image"></i>
            </div>
          </div>
        </template>
        
        <template #cell(date)="row">
          {{ formatDate(row.item.date) }}
        </template>

        <template #cell(active)="row">
          <div>
            <span :class="['badge', row.item.active ? 'badge-success' : 'badge-secondary']">
              {{ row.item.active ? 'Active' : 'Inactive' }}
            </span>
            <div v-if="isEventPast(row.item.date)" class="text-muted small mt-1">
              {{ getDaysAgo(row.item.date) }}
            </div>
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button 
            size="sm" 
            variant="primary" 
            @click="editEvent(row.item)"
          >
            Edit
          </b-button>
          <b-button 
            size="sm" 
            variant="danger" 
            @click="deleteEvent(row.item)"
          >
            Delete
          </b-button>
        </template>
      </b-table>
    </div>

    <!-- Add/Edit Event Modal -->
    <b-modal
      v-model="showModal"
      :title="editMode ? 'Edit Event' : 'New Event'"
      size="lg"
      modal-class="event-modal"
      @ok="handleSubmit"
      @hidden="resetForm"
      no-close-on-backdrop
    >
      <b-form class="event-form">
        <div class="form-row">
          <div class="col-12 mb-4">
            <label>Event Image</label>
            <div class="image-upload-container" @click="triggerFileInput">
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                class="file-input"
                @change="onFileSelected"
              />
              <div v-if="form.image" class="preview-container">
                <img :src="form.image" class="preview-image" alt="Event image preview" />
                <button class="btn-change">
                  Change Image
                </button>
              </div>
              <div v-else class="upload-placeholder">
                <i class="fas fa-cloud-upload-alt"></i>
                <span>Click to upload</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 mb-4">
            <label>Event Title</label>
            <b-form-input 
              v-model="form.title" 
              placeholder="Enter event title"
              class="form-control-lg"
              required
            ></b-form-input>
          </div>
        </div>

        <div class="form-row">
          <div class="col-12 mb-4">
            <label>Description</label>
            <b-form-textarea 
              v-model="form.description" 
              placeholder="Enter event description"
              rows="4"
              class="form-control-lg"
            ></b-form-textarea>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-4">
            <label>Date</label>
            <flat-pickr
              v-model="form.date"
              class="form-control form-control-lg"
              :config="dateConfig"
              placeholder="Select date"
            ></flat-pickr>
          </div>
          <div class="col-md-6 mb-4">
            <label>Time</label>
            <flat-pickr
              v-model="form.time"
              class="form-control form-control-lg"
              :config="timeConfig"
              placeholder="Select time"
            ></flat-pickr>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-8 mb-4">
            <label>Facebook Event Link</label>
            <b-form-input 
              v-model="form.link" 
              type="url"
              placeholder="https://facebook.com/events/..."
              class="form-control-lg"
            ></b-form-input>
          </div>
          <div class="col-md-4 mb-4">
            <label class="d-block">Status</label>
            <div class="custom-control custom-switch">
              <input 
                type="checkbox" 
                class="custom-control-input" 
                id="eventActive"
                v-model="form.active"
              >
              <label class="custom-control-label" for="eventActive">
                {{ form.active ? 'Active' : 'Inactive' }}
              </label>
            </div>
          </div>
        </div>
      </b-form>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button 
            variant="outline-secondary" 
            @click="showModal = false"
          >
            Cancel
          </b-button>
          <b-button 
            variant="primary" 
            @click="handleSubmit"
            :disabled="!form.title || !form.date || !form.time"
          >
            {{ editMode ? 'Save Changes' : 'Create Event' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { db, storage } from "@/db.js";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    flatPickr
  },
  data() {
    return {
      events: [],
      timeFilter: 'all',
      timeFilterOptions: [
        { value: 'all', text: 'All Events' },
        { value: 'upcoming', text: 'Upcoming Events' },
        { value: 'past', text: 'Past Events' }
      ],
      fields: [
        { key: 'image', label: 'Image' },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'active', label: 'Status' },
        { key: 'actions', label: 'Actions' }
      ],
      filter: '',
      filterOn: ['title', 'description'],
      showModal: false,
      editMode: false,
      editId: null,
      form: this.getInitialForm(),
      dateConfig: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'F j, Y',
        minDate: 'today'
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: false
      }
    };
  },
  mounted() {
    this.loadEvents();
  },
  computed: {
    filteredEvents() {
      const now = new Date();
      
      switch(this.timeFilter) {
        case 'upcoming':
          return this.events.filter(event => {
            const eventDate = event.date.toDate();
            return eventDate >= now;
          });
        case 'past':
          return this.events.filter(event => {
            const eventDate = event.date.toDate();
            return eventDate < now;
          });
        default:
          return this.events;
      }
    }
  },
  methods: {
    getInitialForm() {
      return {
        title: '',
        description: '',
        date: '',
        time: '',
        image: '',
        imageFile: null,
        link: '',
        active: true
      };
    },
    async loadEvents() {
      const snapshot = await db.collection('events2')
        .orderBy('date', 'asc')
        .get();
        
      const now = new Date();
      const updates = [];

      this.events = snapshot.docs
        .map(doc => {
          const data = doc.data();
          const eventDate = data.date.toDate();
          const diffTime = now - eventDate;
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          
          // If event is more than a day old and still active, update it to inactive
          if (diffDays > 1 && data.active) {
            updates.push(db.collection('events2').doc(doc.id).update({ active: false }));
            data.active = false;
          }

          return {
            id: doc.id,
            ...data
          };
        })
        .sort((a, b) => {
          const dateA = a.date.toDate();
          const dateB = b.date.toDate();
          
          // Put upcoming events first, sorted by closest date
          if (dateA >= now && dateB >= now) {
            return dateA - dateB;
          }
          // Put past events last, sorted by most recent
          if (dateA < now && dateB < now) {
            return dateB - dateA;
          }
          // Always put upcoming events before past events
          return dateA >= now ? -1 : 1;
        });

      // Apply any pending status updates
      if (updates.length > 0) {
        await Promise.all(updates);
      }
    },
    formatDate(date) {
      if (!date) return '';
      const d = date.toDate ? date.toDate() : new Date(date);
      return moment(d).format('MMMM Do YYYY, h:mm a');
    },
    triggerFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.click();
      }
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        if (this.form.image && this.form.image.startsWith('blob:')) {
          URL.revokeObjectURL(this.form.image);
        }
        
        this.form.imageFile = file;
        this.form.image = URL.createObjectURL(file);
      }
    },
    showNewModal() {
      this.editMode = false;
      this.showModal = true;
    },
    async editEvent(event) {
      this.editMode = true;
      this.editId = event.id;
      const eventDate = event.date.toDate ? event.date.toDate() : new Date(event.date);
      this.form = {
        ...event,
        date: moment(eventDate).format('YYYY-MM-DD'),
        time: moment(eventDate).format('HH:mm'),
        imageFile: null
      };
      this.showModal = true;
    },
    async deleteEvent(event) {
      if (confirm('Are you sure you want to delete this event?')) {
        try {
          await db.collection('events2').doc(event.id).delete();
          if (event.image) {
            const imageRef = storage.refFromURL(event.image);
            await imageRef.delete();
          }
          this.loadEvents();
        } catch (error) {
          console.error('Error deleting event:', error);
        }
      }
    },
    async handleSubmit(evt) {
      if (evt) evt.preventDefault();
      
      try {
        let imageUrl = this.form.image;
        
        if (this.form.imageFile) {
          const fileRef = storage.ref().child(`events/${Date.now()}_${this.form.imageFile.name}`);
          await fileRef.put(this.form.imageFile);
          imageUrl = await fileRef.getDownloadURL();
        }

        const dateTime = moment(`${this.form.date} ${this.form.time}`).toDate();
        const now = new Date();
        const diffTime = now - dateTime;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        // Automatically set active status based on date
        const isActive = diffDays <= 1;

        const eventData = {
          title: this.form.title,
          description: this.form.description,
          date: firebase.firestore.Timestamp.fromDate(dateTime),
          image: imageUrl,
          link: this.form.link,
          active: isActive
        };

        if (this.editMode) {
          await db.collection('events2').doc(this.editId).update(eventData);
        } else {
          await db.collection('events2').add(eventData);
        }

        this.showModal = false;
        this.loadEvents();
      } catch (error) {
        console.error('Error saving event:', error);
      }
    },
    resetForm() {
      if (this.form.image && this.form.image.startsWith('blob:')) {
        URL.revokeObjectURL(this.form.image);
      }
      this.form = this.getInitialForm();
      this.editMode = false;
    },
    isEventPast(date) {
      if (!date) return false;
      const eventDate = date.toDate ? date.toDate() : new Date(date);
      return eventDate < new Date();
    },
    getDaysAgo(date) {
      if (!date) return '';
      const eventDate = date.toDate ? date.toDate() : new Date(date);
      const now = new Date();
      const diffTime = Math.abs(now - eventDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      return `${diffDays} days ago`;
    }
  }
};
</script>

<style>
/* Modal Styles */
.event-modal .modal-content {
  background: #1c1c1c !important;
  border: 1px solid #444 !important;
}

.event-modal .modal-header {
  border-bottom: 1px solid #444 !important;
  background: #2c2c2c !important;
}

.event-modal .modal-footer {
  border-top: 1px solid #444 !important;
  background: #2c2c2c !important;
}

.event-modal .close {
  color: #fff !important;
}

.event-form label {
  font-weight: 600;
  margin-bottom: 8px;
  color: #fff !important;
}

.event-form .form-control-lg {
  background: #2c2c2c !important;
  border: 1px solid #444 !important;
  color: #fff !important;
  border-radius: 8px !important;
  padding: 12px !important;
}

.event-form .form-control-lg:focus {
  box-shadow: none !important;
  border-color: #eb9e34 !important;
}

.event-image-container {
  width: 240px;
  height: 135px;
  border-radius: 6px;
  overflow: hidden;
  background: #1c1c1c;
}

.event-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 24px;
}

/* Image upload styles */
.image-upload-container {
  border: 2px dashed #444;
  border-radius: 6px;
  overflow: hidden;
  background: #1c1c1c;
  width: 240px;
  height: 135px;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.preview-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
}

.upload-placeholder i {
  font-size: 20px;
}

.upload-placeholder span {
  display: none;
}

.image-upload-container:hover .upload-placeholder i {
  color: #fff;
}

.image-upload-container:hover {
  border-color: #666;
}

.btn-change {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.preview-container:hover .btn-change {
  opacity: 1;
}

/* Vue Flatpickr styles */
.flatpickr-calendar {
  background: #2c2c2c !important;
  border: 1px solid #444 !important;
  color: #fff !important;
}

.flatpickr-calendar .flatpickr-months,
.flatpickr-calendar .flatpickr-weekdays {
  background: #1c1c1c !important;
}

.flatpickr-calendar .flatpickr-day {
  color: #fff !important;
}

.flatpickr-calendar .flatpickr-day.selected {
  background: #eb9e34 !important;
  border-color: #eb9e34 !important;
}

.flatpickr-calendar .flatpickr-day:hover {
  background: rgba(235, 158, 52, 0.2) !important;
}

.flatpickr-calendar .flatpickr-day.disabled {
  color: #666 !important;
}

/* Cropper styles */
.cropper-container {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1060 !important;
  width: 90vw !important;
  max-width: 600px !important;
  height: 400px !important;
  background: #1c1c1c !important;
  border: 1px solid #444 !important;
  border-radius: 8px !important;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.7) !important;
}

.cropper-view-box,
.cropper-face {
  border-radius: 4px !important;
}
</style> 