import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=6bf44802&scoped=true&"
import script from "./Events.vue?vue&type=script&lang=js&"
export * from "./Events.vue?vue&type=script&lang=js&"
import style0 from "./Events.vue?vue&type=style&index=0&id=6bf44802&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf44802",
  null
  
)

export default component.exports