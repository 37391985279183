<template>
  <div class="educator-page">
    <div class="container" v-if="educator">
      <div class="educator-header">
        <div class="educator-profile">
          <img :src="educator.image" :alt="educator.name" class="educator-image">
          <div class="educator-info">
            <h1 class="educator-name">{{ educator.name }}</h1>
            <div class="social-links" v-if="educator.instagram">
              <a :href="educator.instagram" target="_blank" class="instagram-link">
                <i class="fa fa-instagram"></i>
                {{ formatInstagram(educator.instagram) }}
              </a>
            </div>
            <p class="educator-bio">{{ educator.bio }}</p>
          </div>
        </div>
      </div>

      <div class="educator-videos" v-if="posts && posts.length">
        <h2 class="section-title">Videos by {{ educator.name }}</h2>
        <div class="video-grid">
          <div v-for="post in posts" :key="post.id" class="video-item">
            <post-card :post="post"></post-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from '../components/PostCard.vue';

export default {
  name: 'Educator',
  components: { PostCard },
  data() {
    return {
      educator: null,
      posts: []
    }
  },
  mounted() {
    this.getEducator();
  },
  methods: {
    formatInstagram(url) {
      return url.replace('https://www.instagram.com/', '@').replace('/', '');
    },
    async getEducator() {
      try {
        const snapshot = await db.collection('educators')
          .doc(this.$route.params.educatorId)
          .get();
        
        if (snapshot.exists) {
          this.educator = { id: snapshot.id, ...snapshot.data() };
          this.getPosts();
        }
      } catch (error) {
        console.error('Error fetching educator:', error);
      }
    },
    getPosts() {
      this.$bind(
        "posts",
        db.collection("posts")
          .where("Educator", "==", this.educator.id)
          .orderBy("PublishedDate", "desc")
      );
    }
  }
}
</script>

<style scoped>
.educator-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.educator-name {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

.educator-header {
  margin-bottom: 60px;
}

.educator-profile {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  align-items: start;
}

.educator-image {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.educator-info {
  color: #fff;
}

.social-links {
  margin-bottom: 20px;
}

.instagram-link {
  display: inline-flex;
  align-items: center;
  color: #eb9e34;
  text-decoration: none;
  font-size: 1.1rem;
}

.instagram-link i {
  margin-right: 8px;
}

.educator-bio {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.section-title {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 30px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.video-item {
  transition: transform 0.3s ease;
}

.video-item:hover {
  transform: translateY(-5px);
}

@media (max-width: 991px) {
  .educator-profile {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .educator-image {
    max-width: 300px;
  }

  .video-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .educator-page {
    padding: 40px 0;
  }

  .educator-name {
    font-size: 2rem;
  }

  .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
  }
}
</style>