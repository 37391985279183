<template>
  <div class="rest-page">
    <div class="container">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">The Rest</h1>
      </div>

      <!-- Partners Section -->
      <section class="content-section">
        <div class="content-container">
          <h2 class="section-title">Partners and Discounts</h2>
          
          <div class="content-box">
            <div class="warning-message">
              <i class="fa fa-exclamation-circle warning-icon"></i>
              <p>DO NOT SHARE this PDF with non-members! These vendors will be checking to make sure you are a member of The DJ's Vault.</p>
            </div>
            
            <a 
              href="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/DJV_Discounts-and-Partners.pdf?alt=media&token=f3aa0676-47cb-4496-879e-cdf22cacfbce" 
              target="_blank"
              class="download-button"
            >
              <i class="fa fa-file-pdf-o"></i>
              <span>Download Partners & Discounts PDF</span>
            </a>
          </div>
        </div>
      </section>

      <!-- Facebook Group Section -->
      <section class="content-section">
        <div class="content-container">
          <h2 class="section-title">Members Only Facebook Group</h2>
          <div class="content-box">
            <a
              href="https://www.facebook.com/groups/thedjsvaultmembersonly"
              target="_blank"
              class="facebook-button"
            >
              <i class="fa fa-facebook"></i>
              <span>Click Here To Enter</span>
            </a>
          </div>
        </div>
      </section>

      <!-- FAQ Section -->
      <section class="content-section">
        <div class="content-container">
          <h2 class="section-title">Frequently Asked Questions (FAQ)</h2>
          <div class="faq-list">
            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>I'm unable to Login</span>
              </div>
              <div class="faq-answer">
                Be sure to use the email address that you used to sign up for
                The DJ's Vault. Copy/Paste the password (with no spaces) into
                the login screen. If this still doesn't work, email <a
                  href="mailTo:help@thedjsvault.com"
                >help@thedjsvault.com</a>.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>I signed up but didn't receive a login email</span>
              </div>
              <div class="faq-answer">
                Check your spam folder. Seriously, this is usually the problem
                or you entered an invalid email (which we can fix if you
                contact us at <a
                  href="mailTo:help@thedjsvault.com"
                >help@thedjsvault.com</a>).
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>How can I Be Removed From Your Email or Text List?</span>
              </div>
              <div class="faq-answer">
                Contact
                <a href="mailTo:help@thedjsvault.com">help@thedjsvault.com</a>
                - Warning, if you mark our email as spam, you may be unable to
                sign back up for The DJ's Vault or make future purchases with
                us at a later date. Our system literally blocks your email
                address so we can never email you again.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>How do I cancel my membership?</span>
              </div>
              <div class="faq-answer">
                Contact us at <a
                  href="mailTo:help@thedjsvault.com"
                >help@thedjsvault.com</a>. Cancellations have to be manually processed in our system.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>How do I cancel via PayPal</span>
              </div>
              <div class="faq-answer">
                If you attempt to cancel your membership through PayPal,
                please let us know so we can cancel the re-billing in our
                system. An automatic re-bill may still process.
                <br><br>
                If you cancel the rebill yourself inside of PayPal, you will lose access to the site immediately. Please email us to give you the rest of your time on your membership.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>Why was my membership canceled?</span>
              </div>
              <div class="faq-answer">
                If your payment does not go through on your renewal date, our
                system will automatically attempt to process the payment a few
                more times over the course of several days. On the last failed
                attempt, your membership will automatically cancel. If you
                were unable to update your payment information during that
                time, simply sign back up or contact us at
                <a href="mailTo:help@thedjsvault.com">help@thedjsvault.com</a>
                and we'll help you re-join.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>Can I call to update my payment information?</span>
              </div>
              <div class="faq-answer">
                To protect you, we cannot take credit card information over
                the phone or by email. You will need to update this
                information directly within The DJ's Vault.
              </div>
            </div>

            <div class="faq-item">
              <div class="faq-question">
                <i class="fa fa-question-circle"></i>
                <span>How Do I Change My Billing Info for The DJ's Vault Monthly/Annual Payments</span>
              </div>
              <div class="faq-answer">
                <ol class="billing-steps">
                  <li>
                    <a
                      target="_blank"
                      href="https://thedjsvault.kartra.com/self_billing/FIE3ObpKkirK"
                    >Go here to open the self billing portal</a>
                  </li>
                  <li>Type in your email you signed up for The DJ's Vault with.</li>
                  <li>Search your email for your last DJ's Vault invoice and locate your "ORDER ID" aka Invoice ID. Type it in.</li>
                  <li>Click "Locate Order."</li>
                  <li>Change billing info.</li>
                </ol>
                <div class="additional-info">
                  <a
                    target="_blank"
                    href="https://thedjsvault.kartra.com/help/billing-support/article/1/4"
                  >More information here</a>
                  <p class="help-note">
                    <strong>For any other help, email us at
                    <a href="mailTo:help@thedjsvault.com">help@thedjsvault.com</a>
                    and we'll assist you as soon as possible.</strong>
                  </p>
                  <p class="support-note">
                    PLEASE NOTE: The help desk is not staffed 24/7. Submissions
                    to the help desk or support email may take up to 12 hours to
                    be addressed and we are off on the weekends. Thank you for
                    your patience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Contact and Support Section -->
      <section class="content-section">
        <div class="content-container">
          <h2 class="section-title">Contact and Support</h2>
          <div class="content-box">
            <div class="support-content">
              <p>Have questions? Suggestions for more content? Need to cancel? Refund?</p>
              <p>Contact the Billing & Support
                <a
                  target="_blank"
                  href="https://thedjsvault.kartra.com/help/billing-support/"
                >Help Desk Here</a>
                and we'll assist you as soon as possible.
              </p>
              <p>Click on "Support" at the top right & submit a ticket and we'll help you ASAP (as shown below)</p>
              <img
                src="/images/helpkarta.png"
                class="support-image"
                alt="Help desk support instructions"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
};
</script>

<style scoped>
.rest-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

/* Content Section */
.content-section {
  margin-bottom: 40px;
}

.content-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 30px;
  transition: all 0.3s ease;
}

.content-container:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.15);
}

.section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}

.content-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
}

/* Warning Message */
.warning-message {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 20px;
  background: rgba(235, 158, 52, 0.1);
  border: 1px solid rgba(235, 158, 52, 0.2);
  border-radius: 8px;
  color: #fff;
}

.warning-icon {
  color: #eb9e34;
  font-size: 1.5rem;
  margin-top: 2px;
}

.warning-message p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

/* Download Button */
.download-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: #eb9e34;
  color: #fff;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.download-button:hover {
  background: #d88f2a;
  color: #fff;
  transform: translateY(-2px);
}

.download-button i {
  font-size: 1.2rem;
}

/* Facebook Button */
.facebook-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: #1877f2;
  color: #fff;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.facebook-button:hover {
  background: #0d6efd;
  color: #fff;
  transform: translateY(-2px);
}

.facebook-button i {
  font-size: 1.2rem;
}

/* FAQ Styles */
.faq-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.02);
  overflow: hidden;
  transition: all 0.2s ease;
}

.faq-item:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.03);
}

.faq-question {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  background: rgba(255, 255, 255, 0.02);
}

.faq-question i {
  color: #eb9e34;
  font-size: 1.2rem;
}

.faq-answer {
  padding: 20px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.faq-answer a {
  color: #eb9e34;
  text-decoration: none;
  transition: color 0.2s ease;
}

.faq-answer a:hover {
  color: #d88f2a;
  text-decoration: underline;
}

/* Billing Steps */
.billing-steps {
  padding-left: 20px;
  margin: 0 0 20px 0;
}

.billing-steps li {
  margin-bottom: 12px;
}

.additional-info {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.help-note {
  margin: 16px 0;
}

.support-note {
  padding: 16px;
  background: rgba(235, 158, 52, 0.1);
  border-radius: 6px;
  margin-top: 16px;
}

/* Support Section */
.support-content {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.support-content p {
  margin-bottom: 16px;
}

.support-image {
  max-width: 800px;
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .rest-page {
    padding: 60px 0;
  }

  .container {
    padding: 0 15px;
  }

  .page-header {
    margin-bottom: 30px;
  }

  .page-title {
    font-size: 2rem;
  }

  .content-container {
    padding: 20px;
  }

  .section-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .warning-message {
    padding: 16px;
  }

  .download-button {
    width: 100%;
    justify-content: center;
  }

  .faq-question {
    padding: 16px;
    font-size: 1rem;
  }

  .faq-answer {
    padding: 16px;
  }

  .billing-steps {
    padding-left: 16px;
  }
}

@media (max-width: 480px) {
  .rest-page {
    padding: 40px 0;
  }

  .page-title {
    font-size: 1.8rem;
  }

  .warning-message {
    flex-direction: column;
    gap: 12px;
    text-align: center;
    align-items: center;
  }
}

.img-max {
  max-width: 800px;
  width: 100%;
}

.question {
  font-size: 24px;
}

.question i {
  padding-right: 6px;
}
</style>
