<template>
  <div class="section-page">
    <div class="container">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="section-title">{{ capitalizeWords(sectionName) }}</h1>
      </div>

      <!-- Content Grid -->
      <section 
        v-for="(subsection, i) in currentSection.subsections" 
        :key="i"
        class="content-section"
      >
        <div class="video-grid-container" v-if="getSubsectionPosts(subsection).length">
          <h2 class="subsection-title">{{ capitalizeWords(subsection) }}</h2>
          <div class="video-grid">
            <div 
              v-for="post in getSubsectionPosts(subsection)" 
              :key="post.id" 
              class="video-item"
            >
              <post-card :post="post"></post-card>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from "../components/PostCard.vue";
import _ from "lodash";
// import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import Slider from '../components/Slider.vue';


export default {
  name: "Home",
  components: { PostCard },
  data: function () {

    return {
      posts: [],
      sections: [],
      filteredPosts: [],
      currentSection: {},
      sectionName: "",
      subSectionName: "",

      settings: {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },

          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ],
      },
    };
  },
 watch: {
    '$store.state.currentUser.membershipLevel': {
 
      immediate: true,
      handler: "pageLoad",
    },
  },

  mounted () {
    if (this.$store.state.currentUser.membershipLevel) {
      this.pageLoad()
    }

  },
  methods: {
    pageLoad () {
      this.getPosts()
      this.getSections();


      if (this.$route.params.sectionName) {
        this.sectionName = this.$route.params.sectionName;
      }
      if (this.$route.params.subSectionName) {
        this.subSectionName = this.$route.params.subSectionName;
      }
    },

    // slider (className) {
    //   jQuery(className).slick({
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 300,
    //     autoplay: false,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     // appendArrows: $('#sm-slick-arrow'),

    //     nextArrow:
    //       '<a href="#" class="slick-arrow slick-next"><i class= "fa fa-chevron-right"></i></a>',
    //     prevArrow:
    //       '<a href="#" class="slick-arrow slick-prev"><i class= "fa fa-chevron-left"></i></a>',
    //     responsive: [
    //       {
    //         breakpoint: 1400,
    //         settings: {
    //           slidesToShow: 12,
    //           slidesToScroll: 12,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 1100,
    //         settings: {
    //           slidesToShow: 6,
    //           slidesToScroll: 6,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           // arrows: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   });
    // },
    capitalizeWords (str) {
      switch (str) {
        case "djing":
          return "DJing";
          break;
        case "mcing":
          return "MCing";
          break;
        case "website & seo webinars":
          return "Website & SEO Webinars";
          break;
        case "sales tips":
          return "Sales & Pricing Tips"
          break;
        case "PhDJ Workshop Seminars":
          return "PhDJ Workshop Seminars";
          break;
        default:
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
      }
    },

    getPosts () {

      if (this.$store.state.currentUser.membershipLevel == 'lite') {

        this.$bind(
          "posts",
          db.collection("posts").orderBy("PublishedDate", "desc")
            .where("lite", "==", true)
        ).then(() => {
          this.filterPosts();
        });
      } else {
        this.$bind(
          "posts",
          db.collection("posts").orderBy("PublishedDate", "desc")
        ).then(() => {
          this.filterPosts();
        });
      }






    },
    getSubsectionPosts (subsection) {
      //  console.log(subsection, this.filteredPosts);
      return this.filteredPosts.filter((post) => post.Subsection == subsection);
    },

    getSections () {
      this.$bind("sections", db.collection("sections")).then(() => {
        if (this.sectionName) {
          for (let section of this.sections) {
            if (section.section == this.sectionName) {
              this.currentSection = section;
              break;
            }
          }
        }
      });
    },

    filterPosts () {
      this.filteredPosts = [];
      for (let post of this.posts) {
        const postSection = post.Section.toString().toLowerCase();
        const postSubSection = post.Subsection.toString().toLowerCase();

        if (
          postSection == this.sectionName.toLowerCase() &&
          (this.subSectionName == "" || postSubSection == this.subSectionName)
        )


          this.filteredPosts.push(post);


      }

      // this.$nextTick(() => {
      //   jQuery("#tvshows-slider").slick({
      //     centerMode: true,
      //     centerPadding: "200px",
      //     slidesToShow: 1,
      //     nextArrow:
      //       '<button class="NextArrow"><i class="ri-arrow-right-s-line"></i></button>',
      //     prevArrow:
      //       '<button class="PreArrow"><i class="ri-arrow-left-s-line"></i></button>',
      //     arrows: true,
      //     dots: false,
      //     responsive: [
      //       {
      //         breakpoint: 991,
      //         settings: {
      //           arrows: false,
      //           centerMode: true,
      //           centerPadding: "20px",
      //           slidesToShow: 1,
      //         },
      //       },
      //       {
      //         breakpoint: 480,
      //         settings: {
      //           arrows: false,
      //           centerMode: true,
      //           centerPadding: "20px",
      //           slidesToShow: 1,
      //         },
      //       },
      //     ],
      //   });
      // });
    },

    onSubSectionSelected (subsection) {
      this.$router.push(`/section/${this.sectionName}/${subsection}`);
    },

    // onPostClick(post) {
    //   this.$router.push('/post/${post.id}');

    // },

    // getSubSections() {
    //   this.sections.forEach(function (section) {
    //     db.collection("posts")
    //       .where("Section", "==", section.section)
    //       .get()
    //       .then((querySnapshot) => {
    //         const posts = querySnapshot.docs.map((doc) => doc.data());
    //       console.log(section.section)
    //       let subsectionsArr = []
    //         posts.forEach(function (post) {

    //           if (post.Subsection){

    //             subsectionsArr.push(post.Subsection)

    //            let subsectionsUniq = _.uniqBy(subsectionsArr);
    //             console.log(subsectionsUniq)
    //           }

    //         });
    //       });
    //   });
    // },
  },
};
</script>

<style scoped>
.section-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

/* Content Sections */
.content-section {
  margin-bottom: 40px;
}

.content-section:last-child {
  margin-bottom: 0;
}

.video-grid-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 40px;
  transition: all 0.3s ease;
}

.video-grid-container:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.15);
}

.subsection-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 40px;
  text-transform: capitalize;
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.video-item {
  transition: transform 0.3s ease;
  min-width: 0;
}

.video-item:hover {
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 1400px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .section-page {
    padding: 40px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .video-grid {
    grid-template-columns: 1fr;
  }
}
</style>