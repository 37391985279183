<template>
  <div class="container-fluid">
    <h1 class="page-title">Admin Dashboard</h1>
    <b-tabs
      content-class="mt-"
      v-if="adminLoggedIn"
      :value="activeTab"
      @input="onTabChange"
    >
      <b-tab
        title="Posts"
      >
        <div class="row p-3">
          <div class="col-md-4">
            <button
              class="btn btn-danger"
              @click="showNewModal()"
            >
              Add New
            </button>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </div>
        </div>

        <div class="table-responsive">
          <b-table
            striped
            hover
            :items="posts"
            :fields="fields"
            :current-page="currentPage"
            :per-page="500"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
          >
            <template #cell(Thumbnail)="row">
              <img class="post-thumbnail" v-bind:src="row.item.Thumbnail" />
            </template>
            <template #cell(Title)="row">
              <div class="post-info">
                <h5 class="mb-1">{{ row.item.Title }}</h5>
                <small class="text-muted" v-if="row.item.PublishedDate">{{ formatDate(row.item.PublishedDate) }}</small>
              </div>
            </template>
            <template #cell(Section)="row">
              <span class="section-text">{{ row.item.Section }}</span>
            </template>
            <template #cell(Subsection)="row">
              <span class="subsection-text">{{ row.item.Subsection }}</span>
            </template>
            <template #cell(Viewed)="row">
              <span class="views-text">
                {{ row.item.Viewed ? row.item.Viewed.length : 0 }}
              </span>
            </template>
            <template #cell(id)="row">
              <div class="btn-group">
                <button class="btn btn-sm btn-outline-primary" @click="showEditModal(row.item)" title="Edit Post">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-sm btn-outline-danger" @click="confirmDeletePost(row.item)" title="Delete Post">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>

        <b-modal
          id="modal-new-event"
          title="New Event"
          size="lg"
        >
          <EventForm :mode="'new'" />
          <template slot="FOOT_b">
            <span>New</span>
          </template>
        </b-modal>

        <b-modal
          id="modal-new-post"
          title="New Post"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <PostForm
            :post="null"
            :mode="'new'"
            ref="newPostForm"
          />
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <b-button variant="secondary" @click="$bvModal.hide('modal-new-post')">Cancel</b-button>
              <b-button variant="primary" @click="$refs.newPostForm.onSubmit()">Save</b-button>
            </div>
          </template>
        </b-modal>

        <b-modal
          id="modal-edit-post"
          title="Edit Post"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <PostForm
            :post="currentPost"
            :mode="'edit'"
            ref="editPostForm"
          />
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <b-button variant="secondary" @click="$bvModal.hide('modal-edit-post')">Cancel</b-button>
              <b-button variant="primary" @click="$refs.editPostForm.onSubmit()">Save</b-button>
            </div>
          </template>
        </b-modal>
      </b-tab>
      <b-tab title="Users">
        <user-admin></user-admin>
      </b-tab>
      <b-tab title="Events">
        <EventAdmin />
      </b-tab>
      <b-tab title="Paths">
        <div class="row p-3">
          <div class="col-md-4">
            <button class="btn btn-danger" @click="showNewPathModal()">
              Add New Path
            </button>
          </div>
        </div>

        <div class="paths-container">
          <div v-if="paths && paths.length > 0">
            <draggable v-model="paths" @change="updatePathsOrder" handle=".path-drag-handle" class="path-list">
              <div v-for="path in paths" :key="path.id" class="path-section mb-4" v-if="path">
                <div class="path-header d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="path-drag-handle">
                      <i class="fa fa-grip-vertical"></i>
                    </div>
                    <h3>{{ path && path.title || 'Untitled Path' }}</h3>
                  </div>
                  <div class="path-actions">
                    <button class="btn btn-sm btn-primary mr-2" @click="editPath(path)">
                      <i class="fa fa-edit"></i> Edit
                    </button>
                    <button class="btn btn-sm btn-danger" @click="deletePath(path)">
                      <i class="fa fa-trash"></i> Delete
                    </button>
                  </div>
                </div>

                <draggable v-model="path.posts" group="posts" @change="updatePathOrder(path)" v-if="path.posts" handle=".drag-handle">
                  <div v-for="post in path.posts" :key="post.id" class="path-post p-2 mb-2" v-if="post">
                    <div class="d-flex align-items-center">
                      <img :src="post.Thumbnail || ''" class="post-thumbnail mr-3" />
                      <div class="post-info flex-grow-1">
                        <h5 class="mb-1">{{ post.Title || 'Untitled Post' }}</h5>
                        <small class="text-muted">{{ post.Section || 'No Section' }}</small>
                      </div>
                      <div class="d-flex align-items-center">
                        <button class="btn btn-sm btn-outline-secondary drag-handle mr-2">
                          <i class="fa fa-bars"></i>
                        </button>
                        <button class="btn btn-sm btn-outline-danger" @click="removeFromPath(path, post)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </draggable>

                <div class="add-post-section mt-2">
                  <button class="btn btn-sm btn-success" @click="showAddPostModal(path)">
                    <i class="fa fa-plus"></i> Add Post
                  </button>
                </div>
              </div>
            </draggable>
          </div>
          <div v-else class="text-center p-5">
            <h3 class="text-muted">No paths created yet</h3>
            <p class="text-muted">Click "Add New Path" to create your first path</p>
          </div>
        </div>

        <b-modal
          id="modal-new-path"
          title="New Path"
          @ok="createPath"
          ok-title="Create Path"
          ok-variant="success"
          cancel-variant="dark"
        >
          <b-form-group label="Path Title">
            <b-form-input v-model="newPath.title" required></b-form-input>
          </b-form-group>
          <div class="text-muted mt-3">
            <small><i class="fas fa-info-circle"></i> After creating the path, you'll be able to add videos to it.</small>
          </div>
        </b-modal>

        <b-modal
          id="modal-edit-path"
          title="Edit Path"
          @ok="updatePath"
          ok-title="Save Changes"
          ok-variant="success"
          cancel-variant="dark"
        >
          <b-form-group label="Path Title">
            <b-form-input v-model="editingPath.title" required></b-form-input>
          </b-form-group>
        </b-modal>

        <b-modal id="modal-add-post" title="Add Post to Path" size="lg">
          <div class="post-search mb-3">
            <b-form-input
              v-model="postSearch"
              placeholder="Search posts..."
              @input="filterPosts"
            ></b-form-input>
          </div>
          <div class="post-list" style="max-height: 400px; overflow-y: auto;">
            <div
              v-for="post in filteredPosts"
              :key="post.id"
              class="post-item p-2"
              @click="addPostToPath(post)"
            >
              <div class="d-flex align-items-center">
                <img :src="post.Thumbnail" class="post-thumbnail mr-3" />
                <div>
                  <h6 class="mb-1">{{ post.Title }}</h6>
                  <small class="text-muted">{{ post.Section }}</small>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </b-tab>
      <b-tab title="Admins">
        <div class="row p-3">
          <div class="col-md-4">
            <button class="btn btn-danger" @click="showNewAdminModal()">
              Add New Admin
            </button>
          </div>
          <div class="col-md-8">
            <b-form-input
              v-model="adminFilter"
              type="search"
              placeholder="Search admins..."
            ></b-form-input>
          </div>
        </div>

        <div class="table-responsive">
          <b-table
            striped
            hover
            :items="filteredAdmins"
            :fields="adminFields"
            responsive
          >
            <template #cell(email)="row">
              <div class="admin-info">
                <span class="admin-email">{{ row.item.email }}</span>
              </div>
            </template>
            <template #cell(actions)="row">
              <div class="btn-group">
                <button 
                  class="btn btn-sm btn-outline-danger" 
                  @click="confirmDeleteAdmin(row.item)"
                  title="Remove Admin"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>

        <b-modal
          id="modal-new-admin"
          title="Add New Admin"
          @ok="addAdmin"
          ok-title="Add Admin"
          ok-variant="success"
          cancel-variant="dark"
        >
          <b-form-group label="Admin Email">
            <b-form-input 
              v-model="newAdminEmail" 
              type="email" 
              required
              placeholder="Enter email address"
            ></b-form-input>
          </b-form-group>
        </b-modal>
      </b-tab>
      <b-tab title="Educators">
        <div class="row p-3">
          <div class="col-md-4">
            <button class="btn btn-danger" @click="showNewEducatorModal()">
              Add New Educator
            </button>
          </div>
          <div class="col-md-4">
            <b-form-select
              v-model="educatorStatusFilter"
              :options="[
                { value: 'all', text: 'Show All' },
                { value: 'active', text: 'Show Active' },
                { value: 'inactive', text: 'Show Inactive' }
              ]"
              class="status-filter"
            ></b-form-select>
          </div>
          <div class="col-md-4">
            <b-form-input
              v-model="educatorFilter"
              type="search"
              placeholder="Search educators..."
            ></b-form-input>
          </div>
        </div>

        <div class="educators-container">
          <div v-if="educators && educators.length > 0" class="educators-list">
            <div v-for="educator in filteredEducators" :key="educator.id" class="educator-item">
              <div class="educator-info">
                <img :src="educator.image || '/images/placeholder.png'" class="educator-avatar" :alt="educator.name">
                <div class="educator-details">
                  <div class="educator-header">
                    <h3 class="educator-name">{{ educator.name }}</h3>
                    <div class="educator-status" :class="{ 'active': educator.active }">
                      {{ educator.active ? 'Active' : 'Inactive' }}
                    </div>
                  </div>
                  <div class="educator-title">{{ educator.title }}</div>
                  <div class="educator-bio">{{ educator.bio }}</div>
                </div>
              </div>
              <div class="educator-actions">
                <button class="btn btn-sm btn-primary" @click="editEducator(educator)">
                  <i class="fa fa-edit"></i> Edit
                </button>
                <button class="btn btn-sm btn-danger" @click="deleteEducator(educator)">
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </div>
          </div>
          <div v-else class="text-center p-5">
            <h3 class="text-muted">No educators found</h3>
            <p class="text-muted">Click "Add New Educator" to add your first educator</p>
          </div>
        </div>

        <b-modal
          id="modal-new-educator"
          title="New Educator"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <EducatorForm
            :educator="null"
            :mode="'new'"
            ref="newEducatorForm"
            @submit="createEducator"
          />
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <b-button variant="secondary" @click="$bvModal.hide('modal-new-educator')">Cancel</b-button>
              <b-button variant="primary" @click="$refs.newEducatorForm.onSubmit()">Save</b-button>
            </div>
          </template>
        </b-modal>

        <b-modal
          id="modal-edit-educator"
          title="Edit Educator"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <EducatorForm
            :educator="currentEducator"
            :mode="'edit'"
            ref="editEducatorForm"
            @submit="updateEducator"
          />
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <b-button variant="secondary" @click="$bvModal.hide('modal-edit-educator')">Cancel</b-button>
              <b-button variant="primary" @click="$refs.editEducatorForm.onSubmit()">Save</b-button>
            </div>
          </template>
        </b-modal>
      </b-tab>
      <b-tab title="Ads">
        <div class="row p-3">
          <div class="col-md-6">
            <button class="btn btn-danger" @click="showNewAdModal()">
              Add New Ad
            </button>
          </div>
          <div class="col-md-6">
            <b-form-input
              v-model="adFilter"
              type="search"
              placeholder="Search ads..."
            ></b-form-input>
          </div>
        </div>

        <div class="ads-container">
          <draggable v-model="ads" @change="updateAdsOrder" handle=".ad-drag-handle" class="ad-list">
            <div v-for="ad in filteredAds" :key="ad.id" class="ad-item" v-if="ad">
              <div class="ad-content">
                <div class="ad-info">
                  <img class="ad-thumbnail" :src="ad.image || '/images/placeholder.png'" :alt="ad.link">
                  <div class="ad-details">
                    <a :href="ad.link" target="_blank" class="ad-link">{{ ad.link }}</a>
                    <span class="ad-status" :class="{ 'active': ad.active }">
                      {{ ad.active ? 'Active' : 'Inactive' }}
                    </span>
                  </div>
                </div>
                <div class="ad-actions">
                  <button class="btn btn-sm btn-outline-secondary ad-drag-handle">
                    <i class="fa fa-bars"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-primary" @click="editAd(ad)">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-danger" @click="deleteAd(ad)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </draggable>
        </div>

        <b-modal
          id="modal-new-ad"
          :title="currentAd ? 'Edit Ad' : 'New Ad'"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <b-form @submit.prevent="saveAd">
            <div class="form-row">
              <div class="col-md-4">
                <div class="image-upload-container">
                  <img 
                    :src="adImagePreview || (currentAd ? currentAd.image : '/images/placeholder.png')" 
                    class="preview-image"
                    alt="Ad image"
                  >
                  <div class="image-upload-overlay">
                    <label class="btn btn-outline-light mb-0">
                      <i class="fa fa-upload"></i> Upload Image
                      <input 
                        type="file" 
                        accept="image/*"
                        @change="handleAdImageUpload" 
                        class="d-none"
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <b-form-group label="URL">
                  <b-form-input
                    v-model="adForm.link"
                    type="url"
                    required
                    placeholder="https://example.com"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Status">
                  <input type="checkbox" v-model="adForm.active" id="ad-active">
                  <label for="ad-active" class="ml-2">Active</label>
                </b-form-group>

                <b-form-group label="Order">
                  <b-form-input
                    v-model="adForm.order"
                    type="number"
                    required
                    placeholder="Enter display order"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-form>
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <b-button variant="secondary" @click="$bvModal.hide('modal-new-ad')">Cancel</b-button>
              <b-button variant="primary" @click="saveAd">Save</b-button>
            </div>
          </template>
        </b-modal>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { db, storage } from "@/db.js";
import PostForm from "../components/PostForm";
import EventForm from "../components/EventForm";
import UserAdmin from "../components/UserAdmin.vue";
import draggable from 'vuedraggable';
import _ from "lodash";
import usersData from "/members.json";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import EducatorForm from "../components/EducatorForm";
import EventAdmin from "../components/EventAdmin.vue"

const request = require("request");
import moment from "moment";
const axios = require("axios");

export default {
  name: "Home",
  components: { 
    PostForm, 
    UserAdmin, 
    EventForm,
    draggable,
    EducatorForm,
    EventAdmin
  },
  data: function () {
    return {
      activeTab: parseInt(localStorage.getItem('adminTab') || '0'),
      selectedTab: 0,
      selectedRow: 0,
      newEvent: {
        order: null,
        day: null,
        month: null,
        time: null,
        details: null,
      },
      addEvent: false,
      eventFields: [
        {
          key: "image",
          label: "Image",
        }, {
          key: "title",
          label: "Title",
        },
        {
          key: "date",
          label: "Date",
        },
        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],
      fields: [
        {
          key: "Thumbnail",
          sortable: true,
          label: "",
        },
        {
          key: "Title",
          sortable: true,
        },
        {
          key: "Section",
          sortable: true,
        },
        {
          key: "Subsection",
          sortable: true,
        },
        {
          key: "Viewed",
          label: "Views",
          sortable: true,
        },
        {
          key: "id",
          label: "Actions",
          sortable: false,
        },
      ],
      posts: [],
      events: [],
      currentPage: 1,
      currentPost: {},
      filter: "",
      filterOn: ["Title"],
      existingUsers: usersData,
      users: [],
      suggestions: [],
      adminLoggedIn: true,
      paths: [],
      newPath: {
        title: "",
        posts: [],
      },
      editingPath: {
        title: "",
        posts: []
      },
      postSearch: "",
      filteredPosts: [],
      educators: [],
      currentEducator: null,
      educatorFilter: "",
      educatorStatusFilter: 'all',
      adFilter: "",
      adFields: [
        {
          key: "image",
          label: "",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "url",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        }
      ],
      currentAd: null,
      adForm: {
        link: "",
        image: "",
        active: true,
        order: 0
      },
      adImagePreview: null,
      adImageFile: null,
      ads: [],
      adminFilter: "",
      adminFields: [
        {
          key: "email",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
      admins: [],
      newAdminEmail: "",
    };
  },
  mounted() {
    this.getPosts();
    console.log("Initializing paths...");
    this.getPaths();
    this.getEducators();
    console.log("Getting ads...");
    this.getAds();
    this.$bind("admins", db.collection("admins")).then(() => {
      console.log("Admins loaded:", this.admins);
    }).catch(error => {
      console.error("Error binding admins:", error);
      this.admins = [];
    });
  },
  computed: {
    filteredEducators() {
      let filtered = this.educators;
      
      // Filter by status
      if (this.educatorStatusFilter === 'active') {
        filtered = filtered.filter(educator => educator.active);
      } else if (this.educatorStatusFilter === 'inactive') {
        filtered = filtered.filter(educator => !educator.active);
      }
      
      // Filter by search text
      if (this.educatorFilter) {
        const search = this.educatorFilter.toLowerCase();
        filtered = filtered.filter(educator => 
          educator.name.toLowerCase().includes(search) ||
          educator.title?.toLowerCase().includes(search) ||
          educator.bio?.toLowerCase().includes(search)
        );
      }
      
      return filtered;
    },
    filteredAds() {
      let filtered = this.ads;
      if (this.adFilter) {
        const search = this.adFilter.toLowerCase();
        filtered = filtered.filter(ad => 
          ad.link.toLowerCase().includes(search)
        );
      }
      return filtered;
    },
    filteredAdmins() {
      if (!this.adminFilter) {
        return this.admins;
      }
      const search = this.adminFilter.toLowerCase();
      return this.admins.filter(admin => 
        admin.email.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    onTabChange(newTab) {
      this.activeTab = newTab;
      localStorage.setItem('adminTab', newTab.toString());
    },
    async addNewEvent () {
      const res = await db.collection("events2").add(this.newEvent);
    },
    deleteEvent (data) {
      let self = this;
      db.collection("events2")
        .doc(data.item.id)
        .delete()
        .then(() => {
          this.$bind("events", db.collection("events2")).then(function () {
            var sortedObjs = _.sortBy(this.events, "order");
            this.events = sortedObjs;
          });
        });
    },
    
    handleSaveRow (data) { 
      db.collection("events2")
        .doc(data.item.id)
        .update({
          month: data.item.month,
          day: data.item.day,
          time: data.item.time,
          details: data.item.details,
          order: data.item.order,
        })
        .then(() => {
          this.selectedRow = 0;
          console.log("SAVED", data.item);
        });
    },
    handleEditRow (data) {
      this.selectedRow = {
        [data.index]: !this.selectedRow[data.index],
      };
    },
    createUsers () {
      usersData.slice(1000, 1500).forEach(function (user) {
        setTimeout(function () {
          addUser(user);
        }, 1000);
      });

      function addUser (user) {
        const _this = this;
        var options = {
          method: "POST",
          url:
            "https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/kartra",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lead: {
              first_name: user.FirstName,
              last_name: user.LastName,
              email: user.Email,
              phone: "",
            },
            action: "membership_granted",
            action_details: {
              membership: {
                level_name: user.Membership,
              },
            },
          }),
        };
        request(options, function (error, response) {
          if (error) {
            console.error("ERROR:" + user.Email, error);
          }
        });
      }
    },
    getPosts () {
      const _this = this;
      this.$bind(
        "posts",
        db.collection("posts").orderBy("PublishedDate", "desc")
      ).then(function () { });

      this.$bind("admins", db.collection("admins")).then(function () { });

      let self = this;
      const d = new Date();
      this.$bind("events", db.collection("events2").orderBy("date").startAt(d)).then(function () {
        var sortedObjs = _.sortBy(self.events, "order");
        self.events = sortedObjs;
      });

      this.$bind(
        "suggestions",
        db.collection("suggestions")
      ).then(function () { });
    },
    showNewModal () {
      this.$bvModal.show("modal-new-post");
    },
    showNewEventModal () {
      this.$bvModal.show("modal-new-event");
    },
    showEditModal (post) {
      console.log({ post });
      this.currentPost = post;
      this.$bvModal.show("modal-edit-post");
    },
    deletePost (post) {
      if (confirm("Do you really want to delete?")) {
        db.collection("posts")
          .doc(post.id)
          .delete();
      }
    },
    fixDates () {
      this.posts.forEach((post) => {
        if (typeof post.NewOrder === 'string') {
          console.log(post.Title)
          var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date('01/01/2023'));
          db.collection("posts")
                .doc(post.id)
                .update({
                  NewOrder: myTimestamp
                });
        }
      });
    },
    getDate (d) {
      console.log(d)
      return moment(d).format("MMMM Do YYYY, h:mm:ss a");
    },
    showNewPathModal () {
      this.$bvModal.show("modal-new-path");
    },
    editPath (path) {
      if (!path) {
        console.warn("Attempted to edit null path");
        return;
      }
      this.editingPath = { 
        id: path.id,
        title: path.title || "",
        posts: path.posts || []
      };
      this.$bvModal.show("modal-edit-path");
    },
    deletePath (path) {
      if (confirm(`Are you sure you want to delete the path "${path.title}"? This cannot be undone.`)) {
        db.collection("paths").doc(path.id).delete();
      }
    },
    createPath () {
      const newPathData = {
        title: this.newPath.title,
        posts: [],
        order: this.paths.length,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      
      db.collection("paths").add(newPathData)
        .then(() => {
          this.newPath = {
            title: "",
            posts: []
          };
          this.$bvModal.hide("modal-new-path");
          alert("Path created! Please add exactly 6 videos to this path.");
        })
        .catch(error => {
          console.error("Error creating path:", error);
        });
    },
    updatePath () {
      db.collection("paths").doc(this.editingPath.id).update({
        title: this.editingPath.title,
      });
      this.$bvModal.hide("modal-edit-path");
    },
    showAddPostModal (path) {
      this.editingPath = path;
      this.postSearch = "";
      this.filteredPosts = this.posts;
      
      // Check if path already has 6 posts
      const currentPosts = path.posts || [];
      if (currentPosts.length >= 6) {
        alert("This path already has 6 videos. Please remove a video before adding a new one.");
        return;
      }
      
      this.$bvModal.show("modal-add-post");
    },
    filterPosts () {
      const search = this.postSearch.toLowerCase();
      this.filteredPosts = this.posts.filter(post =>
        post.Title.toLowerCase().includes(search) ||
        post.Section.toLowerCase().includes(search)
      );
    },
    addPostToPath (post) {
      // Get current path's posts
      const currentPosts = this.editingPath.posts || [];
      
      // Check if we already have 6 posts
      if (currentPosts.length >= 6) {
        alert("A path can only have 6 videos. Please remove a video before adding a new one.");
        this.$bvModal.hide("modal-add-post");
        return;
      }

      // Add the new post
      db.collection("paths").doc(this.editingPath.id).update({
        "posts": firebase.firestore.FieldValue.arrayUnion(post)
      });
      this.$bvModal.hide("modal-add-post");
    },
    removeFromPath (path, post) {
      if (confirm(`Remove "${post.Title}" from "${path.title}"?`)) {
        db.collection("paths").doc(path.id).update({
          "posts": firebase.firestore.FieldValue.arrayRemove(post)
        });
      }
    },
    updatePathOrder (path) {
      const order = this.paths.map(p => p.id).indexOf(path.id);
      db.collection("paths").doc(path.id).update({
        order: order
      });
    },
    getPaths() {
      console.log("Getting paths...");
      this.$bind(
        "paths",
        db.collection("paths")
          .orderBy("order", "asc")
      ).then(() => {
        console.log("Paths loaded:", this.paths);
        if (!this.paths) {
          this.paths = [];
        }
      }).catch(error => {
        console.error("Error binding paths:", error);
        this.paths = [];
      });
    },
    confirmDeletePost(post) {
      if (confirm('Are you sure you want to delete this post?')) {
        this.deletePost(post);
      }
    },
    confirmDeleteEvent(data) {
      if (confirm('Are you sure you want to delete this event?')) {
        this.deleteEvent(data);
      }
    },
    updatePathsOrder() {
      this.paths.forEach((path, index) => {
        db.collection("paths").doc(path.id).update({
          order: index
        });
      });
    },
    getPostPaths(post) {
      return this.paths.filter(path => 
        path.posts && path.posts.some(p => p.id === post.id)
      );
    },
    formatDate(date) {
      if (!date || date === 'Invalid date') return '';
      try {
        const d = new Date(date);
        if (isNaN(d.getTime())) return '';
        return moment(d).format("MMM D, YYYY");
      } catch (err) {
        console.error('Error formatting date:', err);
        return '';
      }
    },
    getEducators() {
      this.$bind(
        "educators",
        db.collection("educators")
          .orderBy("name", "asc")
      );
    },
    showNewEducatorModal() {
      this.currentEducator = null;
      this.$bvModal.show("modal-new-educator");
    },
    editEducator(educator) {
      this.currentEducator = educator;
      this.$bvModal.show("modal-edit-educator");
    },
    async deleteEducator(educator) {
      if (!confirm(`Are you sure you want to delete ${educator.name}? This cannot be undone.`)) {
        return;
      }
      
      try {
        await db.collection("educators").doc(educator.id).delete();
      } catch (error) {
        console.error("Error deleting educator:", error);
        alert("Failed to delete educator. Please try again.");
      }
    },
    async createEducator(formData) {
      try {
        await db.collection("educators").add({
          ...formData,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
        this.$bvModal.hide("modal-new-educator");
      } catch (error) {
        console.error("Error creating educator:", error);
        alert("Failed to create educator. Please try again.");
      }
    },
    async updateEducator(formData) {
      if (!this.currentEducator?.id) return;
      
      try {
        await db.collection("educators").doc(this.currentEducator.id).update({
          ...formData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
        this.$bvModal.hide("modal-edit-educator");
      } catch (error) {
        console.error("Error updating educator:", error);
        alert("Failed to update educator. Please try again.");
      }
    },
    getAds() {
      console.log("Getting ads...");
      db.collection("ads").onSnapshot(snapshot => {
        this.ads = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log("Ads loaded:", this.ads);
      }, error => {
        console.error("Error getting ads:", error);
        this.ads = [];
      });
    },
    showNewAdModal() {
      this.currentAd = null;
      this.adForm = {
        link: "",
        image: "",
        active: true,
        order: 0
      };
      this.adImagePreview = null;
      this.adImageFile = null;
      this.$bvModal.show("modal-new-ad");
    },
    editAd(ad) {
      this.currentAd = ad;
      this.adForm = { ...ad };
      this.adImagePreview = null;
      this.adImageFile = null;
      this.$bvModal.show("modal-new-ad");
    },
    async deleteAd(ad) {
      if (!confirm(`Are you sure you want to delete this ad? This cannot be undone.`)) {
        return;
      }
      
      try {
        await db.collection("ads").doc(ad.id).delete();
      } catch (error) {
        console.error("Error deleting ad:", error);
        alert("Failed to delete ad. Please try again.");
      }
    },
    handleAdImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.adImageFile = file;
      this.adImagePreview = URL.createObjectURL(file);
    },
    async uploadAdImage() {
      if (!this.adImageFile) return this.adForm.image;

      const timestamp = Date.now();
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`ads/${timestamp}_${this.adImageFile.name}`);

      try {
        const snapshot = await imageRef.put(this.adImageFile);
        return await snapshot.ref.getDownloadURL();
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },
    async saveAd() {
      try {
        const adData = {
          link: this.adForm.link,
          active: this.adForm.active,
          order: parseInt(this.adForm.order) || 0,
          image: this.adForm.image
        };

        if (this.adImageFile) {
          adData.image = await this.uploadAdImage();
        }

        if (this.currentAd) {
          await db.collection("ads").doc(this.currentAd.id).update(adData);
        } else {
          await db.collection("ads").add(adData);
        }
        
        this.$bvModal.hide("modal-new-ad");
      } catch (error) {
        console.error("Error saving ad:", error);
        alert("Failed to save ad. Please try again.");
      }
    },
    async updateAdsOrder() {
      try {
        const updates = this.ads.map((ad, index) => {
          return db.collection("ads").doc(ad.id).update({
            order: index
          });
        });
        await Promise.all(updates);
      } catch (error) {
        console.error("Error updating ad order:", error);
      }
    },
    showNewAdminModal() {
      this.newAdminEmail = "";
      this.$bvModal.show("modal-new-admin");
    },
    async addAdmin() {
      if (!this.newAdminEmail) return;
      
      try {
        // Check if admin already exists
        const exists = this.admins.some(admin => admin.email === this.newAdminEmail);
        if (exists) {
          alert("This email is already an admin.");
          return;
        }

        await db.collection("admins").add({
          email: this.newAdminEmail,
          addedAt: firebase.firestore.FieldValue.serverTimestamp()
        });

        this.newAdminEmail = "";
      } catch (error) {
        console.error("Error adding admin:", error);
        alert("Failed to add admin. Please try again.");
      }
    },
    async confirmDeleteAdmin(admin) {
      if (!confirm(`Are you sure you want to remove ${admin.email} as an admin?`)) {
        return;
      }
      
      try {
        await db.collection("admins").doc(admin.id).delete();
      } catch (error) {
        console.error("Error removing admin:", error);
        alert("Failed to remove admin. Please try again.");
      }
    },
  },
};
</script>

<style>
.form-group label,
.modal-title {
  color: #fff;
}

.modal-footer {
  border-top: 1px solid #444;
}

.label {
  font-size: 10px;
  color: #aaa;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #2c2c2c;
  border: thin solid #444;
  color: #fff;
}

.nav-tabs .nav-link {
  color: #aaa;
}

/* Paths Management Styles */
.paths-container {
  padding: 20px;
}

.path-section {
  background: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #444;
}

.path-header {
  margin-bottom: 15px;
}

.path-header h3 {
  color: #fff;
  margin: 0;
}

.path-post {
  background: #1c1c1c;
  border: 1px solid #444;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.path-post:hover {
  border-color: #666;
  background: #2c2c2c;
}

.post-thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #444;
}

.post-info {
  min-width: 0;
  flex: 1;
}

.post-info h5 {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .post-info h5 {
    font-size: 1rem;
    -webkit-line-clamp: 1;
  }
}

.post-info small {
  font-size: 0.75rem;
  color: #888;
  display: block;
  margin-top: 2px;
}

.section-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
}

@media (min-width: 768px) {
  .section-badges {
    gap: 0.5rem;
  }
}

.section-badges .badge {
  font-size: 0.75rem;
  padding: 0.35em 0.6em;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .section-badges .badge {
    font-size: 0.85rem;
    padding: 0.4em 0.8em;
  }
}

/* Responsive button group */
.btn-group {
  display: flex;
  gap: 0.25rem;
}

@media (min-width: 768px) {
  .btn-group {
    gap: 0.5rem;
  }
}

.btn-group .btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .btn-group .btn {
    width: 36px;
    height: 36px;
  }
}

/* Responsive table padding */
.table td, .table th {
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .table td, .table th {
    padding: 1rem;
  }
}

/* Responsive search and add new section */
.row.p-3 {
  margin: 0;
  padding: 1rem !important;
}

@media (max-width: 767px) {
  .row.p-3 {
    gap: 1rem;
  }
  
  .row.p-3 > div {
    width: 100%;
    padding: 0;
  }
  
  .row.p-3 .btn-danger {
    width: 100%;
  }
}

/* Make table horizontally scrollable on mobile */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Adjust tab navigation for mobile */
.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid #444;
}

.nav-tabs .nav-item {
  white-space: nowrap;
}

/* Responsive modal */
@media (max-width: 767px) {
  .modal-dialog {
    margin: 0.5rem;
  }
  
  .modal-content {
    border-radius: 8px;
  }
}

/* Ensure views badge stays compact */
.views-badge {
  min-width: 32px;
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
}

@media (min-width: 768px) {
  .views-badge {
    min-width: 40px;
    font-size: 0.9rem;
    padding: 0.4em 0.8em;
  }
}

/* Update existing styles */
.container-fluid {
  padding: 5rem 1rem 1rem 1rem;
}

/* Responsive table styles */
.table-responsive {
  margin: 0;
  padding: 0;
  width: 100%;
}

.post-thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #444;
}

@media (min-width: 768px) {
  .post-thumbnail {
    width: 120px;
    height: 68px;
  }
}

.post-info {
  min-width: 0;
  flex: 1;
}

.post-info h5 {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .post-info h5 {
    font-size: 1rem;
    -webkit-line-clamp: 1;
  }
}

.post-info small {
  font-size: 0.75rem;
  color: #888;
  display: block;
  margin-top: 2px;
}

.section-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
}

@media (min-width: 768px) {
  .section-badges {
    gap: 0.5rem;
  }
}

.section-badges .badge {
  font-size: 0.75rem;
  padding: 0.35em 0.6em;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .section-badges .badge {
    font-size: 0.85rem;
    padding: 0.4em 0.8em;
  }
}

/* Responsive button group */
.btn-group {
  display: flex;
  gap: 0.25rem;
}

@media (min-width: 768px) {
  .btn-group {
    gap: 0.5rem;
  }
}

.btn-group .btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .btn-group .btn {
    width: 36px;
    height: 36px;
  }
}

/* Responsive table padding */
.table td, .table th {
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .table td, .table th {
    padding: 1rem;
  }
}

/* Responsive search and add new section */
.row.p-3 {
  margin: 0;
  padding: 1rem !important;
}

@media (max-width: 767px) {
  .row.p-3 {
    gap: 1rem;
  }
  
  .row.p-3 > div {
    width: 100%;
    padding: 0;
  }
  
  .row.p-3 .btn-danger {
    width: 100%;
  }
}

/* Make table horizontally scrollable on mobile */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Adjust tab navigation for mobile */
.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid #444;
}

.nav-tabs .nav-item {
  white-space: nowrap;
}

/* Responsive modal */
@media (max-width: 767px) {
  .modal-dialog {
    margin: 0.5rem;
  }
  
  .modal-content {
    border-radius: 8px;
  }
}

/* Ensure views badge stays compact */
.views-badge {
  min-width: 32px;
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
}

@media (min-width: 768px) {
  .views-badge {
    min-width: 40px;
    font-size: 0.9rem;
    padding: 0.4em 0.8em;
  }
}

.section-text {
  color: #fff;
  font-size: 0.9rem;
}

.subsection-text {
  color: #888;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .section-text,
  .subsection-text {
    font-size: 1rem;
  }
}

/* Updated Educator Management Styles */
.educators-container {
  padding: 20px;
}

.educators-list {
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 8px;
  overflow: hidden;
}

.educator-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #444;
}

.educator-item:last-child {
  border-bottom: none;
}

.educator-info {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
}

.educator-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #444;
}

.educator-details {
  flex: 1;
  min-width: 0;
}

.educator-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.25rem;
}

.educator-name {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.educator-status {
  font-size: 0.7rem;
  padding: 0.15rem 0.4rem;
  border-radius: 3px;
  background: #dc3545;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.educator-status.active {
  background: #28a745;
}

.educator-title {
  font-size: 0.9rem;
  color: #3498db;
  margin-bottom: 0.25rem;
}

.educator-bio {
  font-size: 0.9rem;
  color: #888;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.educator-actions {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

.educator-actions .btn {
  padding: 0.375rem 0.75rem;
}

.status-filter {
  background: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
  height: 100%;
}

.status-filter:focus {
  background: #2c2c2c;
  border-color: #3498db;
  color: #fff;
  box-shadow: none;
}

@media (max-width: 767px) {
  .educators-container {
    padding: 10px;
  }

  .educator-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
  }

  .educator-info {
    width: 100%;
  }

  .educator-actions {
    width: 100%;
    margin-left: 0;
    justify-content: flex-end;
  }

  .educator-avatar {
    width: 48px;
    height: 48px;
  }
}

/* Ad Management Styles */
.ads-container {
  padding: 20px;
  background: #2c2c2c;
  border-radius: 8px;
  border: 1px solid #444;
}

.ad-item {
  padding: 1rem;
  border-bottom: 1px solid #444;
}

.ad-item:last-child {
  border-bottom: none;
}

.ad-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ad-info {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
}

.ad-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #444;
}

.ad-details {
  flex: 1;
  min-width: 0;
}

.ad-link {
  display: block;
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ad-status {
  font-size: 0.7rem;
  padding: 0.15rem 0.4rem;
  border-radius: 3px;
  background: #dc3545;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.ad-status.active {
  background: #28a745;
}

.ad-actions {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

.ad-actions .btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-drag-handle {
  cursor: move;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.ad-drag-handle:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .ad-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .ad-actions {
    width: 100%;
    margin-left: 0;
    justify-content: flex-end;
  }
}

.path-list,
.ad-list {
  position: relative;
}

.path-list > .sortable-ghost,
.ad-list > .sortable-ghost {
  opacity: 0;
}

.path-list > .sortable-drag,
.ad-list > .sortable-drag {
  opacity: 0.5;
}

.path-list > .sortable-chosen::before,
.ad-list > .sortable-chosen::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background: #3498db;
  animation: pulse 1s infinite;
}

.path-list > .sortable-chosen::after,
.ad-list > .sortable-chosen::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #3498db;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.page-title {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
</style>
