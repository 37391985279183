<template>
  <header class="header">
    <nav class="nav-container">
      <!-- Left: Logo -->
      <router-link to="/" class="logo-link">
        <img src="/images/logo.png" alt="The DJ's Vault" class="logo" />
      </router-link>

      <!-- Center: Navigation -->
      <div class="nav-menu" :class="{ 'show': isMobileMenuOpen }">
        <router-link to="/" class="nav-link" exact>Home</router-link>
        <router-link to="/section/marketing" class="nav-link">Marketing</router-link>
        <router-link to="/section/sales" class="nav-link">Sales</router-link>
        <router-link to="/section/multi-op" class="nav-link">Multi-Op</router-link>
        <router-link to="/section/gear" class="nav-link">Gear</router-link>
        <router-link to="/section/performance" class="nav-link">Performance</router-link>
        <router-link to="/documents" class="nav-link">Docs</router-link>
        <router-link to="/educators" class="nav-link">Educators</router-link>
        <router-link to="/rest" class="nav-link">The Rest</router-link>
        <router-link to="/section/annual" class="nav-link">Annual Bonuses</router-link>
      </div>

      <!-- Right: Actions -->
      <div class="nav-actions">
        <!-- Search -->
        <button class="icon-btn" @click="goToSearch" aria-label="Search">
          <i class="fa fa-search"></i>
        </button>

        <!-- Favorites -->
        <!-- <router-link to="/favorites" class="icon-btn" aria-label="Favorites">
          <i class="fa fa-heart"></i>
        </router-link> -->

        <!-- User -->
        <div class="user-menu">
          <button class="icon-btn" aria-label="User">
            <i class="fa fa-user"></i>
          </button>
          <div class="user-dropdown">
            <button @click="logout" class="dropdown-btn">Logout</button>
          </div>
        </div>

        <!-- Mobile Menu -->
        <button class="menu-toggle" @click="toggleMobileMenu" aria-label="Menu">
          <i class="fa fa-bars"></i>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import firebase from "firebase/app";

import { db } from "@/db.js";
export default {
  data: function() {
    return {
      posts: [],
      sidebarOpened: false,
      query: "",
      suggestions: [],
      sections: [],
      mayhem: [
        "dominick.pirone@gmail.com",
        "matt@mountaineventservices.com",
        "kraig@jukeboxhits.com",
        "info@mastersofmusicdj.com",
        "jason@spencerweddings.com",
        "GERRY@ULTRAEVENTGROUP.COM",
        "john@perfectdj.net",
        "randy@bunndjcompany.com",
        "craig@elitesoundskc.com",
        "djstan@stansmobiledj.com",
        "djktpg@gmail.com",
        "djrick@atrdj.com",
        "larry@aandadj.com",
        "mcocco@thecoccogroup.com",
        "1cooldj@gmail.com",
        "djmac6067@gmail.com",
        "mike@newcitydj.com",
        "tony@mndiscjockeys.com",
        "joe@exceptional-entertainment.com",
        "ben@highergroundent.com",
        "djgenew@morethanjustdancedj.com",
        "Djchaosproductions@gmail.com",
        "uptownsounddj@hotmail.com",
        "george.koury@gmail.com",
        "Info@thedjmajesty.com",
        "dj-g52@outlook.com",
        "Timothysmith77@aol.com",
        "jared@purevisiondj.com",
        "robert@sonicsculptor.com",
        "FourStarDJ@hotmail.com",
        "Spindiesel@mac.com",
        "Jerry@djjerryandsons.com",
        "dj@shavanosoundz.com",
        "info@sonicdjs.com",
        "sam@soireedjs.com",
      ],
      isScrolled: false,
      isMobileMenuOpen: false,
      menuItems: [
        { path: '/', label: 'Home', icon: 'fa fa-home' },
        { path: '/section/marketing', label: 'Marketing' },
        { path: '/section/sales', label: 'Sales' },
        { path: '/section/multi-op', label: 'Multi-Op' },
        { path: '/section/gear', label: 'Gear' },
        { path: '/section/performance', label: 'Performance' },
        { path: '/documents', label: 'Docs' },
        { path: '/educators', label: 'Educators' },
        { path: '/rest', label: 'The Rest' },
        { path: '/section/annual', label: 'Annual Bonuses' },
      ]
    };
  },
  computed: {
    userAvatar() {
      return this.$store.state.currentUser.photoURL || '/images/default-avatar.png'
    }
  },
  mounted() {
    //redirect if users going to firebase app
    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }
    this.log();

    this.$bind("posts", db.collection("posts")).then(function() {});
    this.$bind("sections", db.collection("sections")).then(function() {});
    window.addEventListener("click", this.sidebarStateHandler);
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.sidebarStateHandler);
    window.removeEventListener('scroll', this.onScroll);
  },
  watch: {
    query: {
      handler: "queryChangeHandler",
    },
  },
  methods: {
    log() {
      db.collection("log").add({
        name: this.$store.state.currentUser.displayName,
        email: this.$store.state.currentUser.email,
        page: window.location.href,
      });
    },
    goToSearch() {
      this.$router.push('/search');
    },
    go(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    sidebarStateHandler() {
      if (this.sidebarOpened) {
        this.closeSidebar();
      }
    },
    closeSidebar() {
      this.$refs.sidebarMenu.classList.remove("show");
    },
    async queryChangeHandler() {
      this.suggestions = [];
      if (this.query == "") return;

      for (let post of this.posts) {
        const postTitle = post.Title.toString().toLowerCase();

        if (postTitle.includes(this.query.toLowerCase()))
          this.suggestions.push(post);

        if (this.suggestions.length == 20) break;
      }
    },
    onSuggestionClick(post) {
      this.$router.push(`/post/${post.id}`);
    },
    onSubmit() {
      this.$router.push(`/search?query=${this.query}`);
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen
      if (this.isSearchOpen) {
        this.$nextTick(() => {
          this.$el.querySelector('.search-input')?.focus()
        })
      }
    },
    onScroll() {
      this.isScrolled = window.scrollY > 50
    },
    async onSearchInput() {
      if (this.searchQuery.length >= 2) {
        // Implement search logic here
        // this.searchResults = await this.performSearch(this.searchQuery)
      } else {
        this.searchResults = []
      }
    },
    onSearchResultClick(result) {
      // Handle search result click
      this.isSearchOpen = false
      this.searchQuery = ''
      this.$router.push(result.path)
    },
    async logout() {
      try {
        await firebase.auth().signOut();
        this.$router.push('/login');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    },
    shouldShowMenuItem(item) {
      if (item.requiresFullMembership) {
        return this.$store.state.currentUser.membershipLevel !== 'lite'
      }
      if (item.requiresAnnual) {
        return this.$store.state.currentUser.membershipLevel === 'annual'
      }
      return true
    },
  },
};
</script>

<style>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(17, 17, 17, 0.95);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
}

.nav-container {
  max-width: 1400px;
  margin: 0 auto;
  height: 64px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.logo-link {
  flex-shrink: 0;
  transition: opacity 0.2s ease;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo {
  height: 36px;
  width: auto;
  display: block;
  border: none;
}

.nav-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  height: 100%;
}

.nav-link {
  color: #999;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.nav-link:hover,
.nav-link.router-link-active {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.icon-btn {
  position: relative;
  color: #999;
  background: none;
  border: none;
  padding: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.icon-btn:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

/* Remove search styles */
.search,
.search-panel,
.search-input {
  display: none;
}

.menu-toggle {
  display: none;
  color: #999;
  background: none;
  border: none;
  padding: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.menu-toggle:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1200px) {
  .nav-menu {
    gap: 0.5rem;
  }
  
  .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    height: 56px;
  }

  .nav-menu {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    background: #111;
    padding: 1rem;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    height: auto;
    max-height: calc(100vh - 56px);
    overflow-y: auto;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .nav-menu.show {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .nav-link {
    padding: 0.75rem 1rem;
    border-radius: 6px;
    display: block;
  }

  .menu-toggle {
    display: block;
  }

  .search-panel {
    position: fixed;
    top: 56px;
    left: 1rem;
    right: 1rem;
    width: auto;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 28px;
  }
}

@media (max-width: 480px) {
  .nav-container {
    padding: 0 1rem;
  }

  .logo {
    height: 24px;
  }
}

.user-menu {
  position: relative;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #1c1c1c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 8px;
  min-width: 120px;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.user-menu:hover .user-dropdown {
  opacity: 1;
  visibility: visible;
}

.dropdown-btn {
  width: 100%;
  padding: 8px 16px;
  color: #999;
  background: none;
  border: none;
  font-size: 0.9rem;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.dropdown-btn:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
</style>
