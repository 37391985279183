<template>
  <div class="search-page">
    <div class="container">
      <!-- Search Header -->
      <div class="page-header">
        <h1 class="page-title">Search The Vault</h1>
        <div class="search-input-wrapper">
          <input
            v-model="query"
            type="text"
            class="form-control search-input vault-search"
            aria-label="Search the Vault"
            placeholder="Start typing to search..."
            autocomplete="off"
          />
          <i class="ri-search-2-line search-icon"></i>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="isSearching" class="text-center mt-5">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Searching...</span>
        </div>
      </div>

      <!-- Results -->
      <div v-else-if="searched" class="search-results">
        <!-- Posts Results -->
        <div v-if="filteredPosts.length" class="content-section">
          <div class="video-grid-container">
            <h2 class="subsection-title">Posts</h2>
            <div class="video-grid">
              <div 
                v-for="post in filteredPosts"
                :key="post.id"
                class="video-item"
              >
                <post-card :post="post"></post-card>
              </div>
            </div>
          </div>
        </div>

        <!-- Document Results -->
        <div v-if="filteredDocs.length" class="content-section">
          <div class="video-grid-container">
            <h2 class="subsection-title">Documents</h2>
            <div class="documents-grid">
              <div 
                v-for="doc in filteredDocs"
                :key="doc.id"
                class="document-item"
              >
                <div class="document-content">
                  <div class="document-icon">
                    <i class="ri-file-text-line"></i>
                  </div>
                  <div class="document-info">
                    <h5>{{ doc.Title }}</h5>
                    <a :href="doc.Document" target="_blank" class="btn btn-primary btn-sm">
                      <i class="ri-download-line mr-1"></i> Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- No Results -->
        <div v-if="!filteredPosts.length && !filteredDocs.length" class="no-results">
          <i class="ri-search-2-line no-results-icon"></i>
          <h4>No results found</h4>
          <p>Try different keywords or check your spelling</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from "../components/PostCard.vue";
import _ from "lodash";

export default {
  components: { PostCard },
  data() {
    return {
      query: "",
      posts: [],
      documents: [],
      filteredPosts: [],
      filteredDocs: [],
      searched: false,
      isSearching: false
    };
  },
  mounted() {
    this.$bind("posts", db.collection("posts").where("Section", "!=", "docs"));
    this.$bind("documents", db.collection("posts").where("Section", "==", "docs"));
    
    if (this.$route.query.tag) {
      this.query = this.$route.query.tag;
      this.search();
    }
  },
  watch: {
    query: {
      handler: _.debounce(function(val) {
        if (val.length >= 2) {
          this.isSearching = true;
          this.search();
        } else {
          this.filteredPosts = [];
          this.filteredDocs = [];
          this.searched = false;
        }
      }, 300)
    }
  },
  methods: {
    search() {
      this.searched = true;
      this.isSearching = true;

      if (!this.query) {
        this.filteredPosts = [];
        this.filteredDocs = [];
        this.isSearching = false;
        return;
      }

      const searchQuery = this.query.toLowerCase();
      
      // Search in all posts
      const allMatchingPosts = this.posts.filter(post => {
        const matchTitle = post.Title?.toLowerCase().includes(searchQuery);
        const matchCategory = Array.isArray(post.Category) 
          ? post.Category.some(cat => cat.toLowerCase().includes(searchQuery))
          : post.Category?.toLowerCase().includes(searchQuery);
        const matchDescription = post.Description?.toLowerCase().includes(searchQuery);
        return matchTitle || matchCategory || matchDescription;
      });

      // Separate posts with documents
      this.filteredPosts = allMatchingPosts.filter(post => !post.Document);
      const postsWithDocs = allMatchingPosts.filter(post => post.Document);

      // Search in documents section and combine with posts that have documents
      const docSectionResults = this.documents.filter(doc => {
        const matchTitle = doc.Title?.toLowerCase().includes(searchQuery);
        const matchCategory = Array.isArray(doc.Category)
          ? doc.Category.some(cat => cat.toLowerCase().includes(searchQuery))
          : doc.Category?.toLowerCase().includes(searchQuery);
        return matchTitle || matchCategory;
      });

      this.filteredDocs = [...docSectionResults, ...postsWithDocs];

      this.isSearching = false;
    }
  }
};
</script>

<style scoped>
.search-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: center;
  padding-top: 20px;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2rem;
  z-index: 10;
}

.vault-search {
  width: 100% !important;
  height: 60px !important;
  padding: 0 1.5rem 0 3.5rem !important;
  font-size: 1.2rem !important;
  border-radius: 8px !important;
  background-color: #2a2a2a !important;
  border: 2px solid #3a3a3a !important;
  color: #ffffff !important;
  transition: all 0.3s ease !important;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  position: relative !important;
  z-index: 11 !important;
}

.vault-search:focus {
  border-color: var(--iq-primary) !important;
  box-shadow: 0 0 0 0.2rem rgba(var(--iq-primary-rgb), 0.25) !important;
  background-color: #2a2a2a !important;
}

.search-icon {
  position: absolute;
  left: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #888;
  pointer-events: none;
}

/* Content Sections */
.content-section {
  margin-bottom: 40px;
}

.video-grid-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 30px;
  transition: all 0.3s ease;
}

.video-grid-container:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.15);
}

.subsection-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.video-item {
  transition: transform 0.3s ease;
  min-width: 0;
}

.video-item:hover {
  transform: translateY(-5px);
}

/* Documents Grid */
.documents-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.document-item {
  background: #2a2a2a;
  border-radius: 10px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
}

.document-item:hover {
  transform: translateY(-5px);
}

.document-content {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.document-icon {
  font-size: 2rem;
  color: var(--iq-primary);
  flex-shrink: 0;
}

.document-info {
  flex: 1;
  min-width: 0;
}

.document-info h5 {
  margin-bottom: 1rem;
  color: #fff;
  font-size: 1rem;
}

.no-results {
  text-align: center;
  padding: 4rem 0;
  color: #888;
}

.no-results-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

/* Responsive Design */
@media (max-width: 1400px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .documents-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .documents-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
  
  .page-title {
    font-size: 2rem;
  }
  
  .video-grid-container {
    padding: 20px;
  }
  
  .documents-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .search-page {
    padding: 60px 0;
  }
  
  .page-title {
    font-size: 1.8rem;
  }
  
  .video-grid {
    grid-template-columns: 1fr;
  }
}
</style>