<template>
  <div class="educators-page">
    <div class="container">
      <div class="page-header">
        <h1 class="page-title">Our Educators</h1>
      </div>
      
      <div class="educators-grid">
        <div v-for="educator in educators" :key="educator.id" class="educator-card">
          <div class="educator-image">
            <img :src="educator.image" :alt="educator.name" class="rounded-image">
          </div>
          <div class="educator-info">
            <div class="educator-content">
              <h2 class="educator-name">{{ educator.name }}</h2>
              <div class="bio-container">
                <p class="educator-bio" :class="{ 'truncated': !educator.showFullBio }">{{ educator.bio }}</p>
                <button 
                  v-if="shouldShowMoreButton(educator.bio)" 
                  @click="toggleBio(educator)" 
                  class="show-more-btn"
                >
                  {{ educator.showFullBio ? 'Show Less' : 'Show More' }}
                </button>
              </div>
            </div>
            <router-link 
              :to="'/educator/' + educator.docId" 
              class="view-videos-btn"
            >
              View Videos
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";

export default {
  name: "Educators",
  data() {
    return {
      educators: []
    };
  },
  mounted() {
    this.getEducators();
  },
  methods: {
    async getEducators() {
      try {
        const snapshot = await db.collection("educators")
          .orderBy("name", "asc")
          .get();
        
        this.educators = snapshot.docs.map(doc => ({
          docId: doc.id,
          ...doc.data(),
          showFullBio: false
        }));
      } catch (error) {
        console.error("Error fetching educators:", error);
      }
    },
    shouldShowMoreButton(bio) {
      const lineHeight = 1.6; // matches CSS line-height
      const fontSize = 16; // base font size in pixels
      const lineHeightPx = lineHeight * fontSize;
      const maxHeight = lineHeightPx * 3; // three lines
      
      // Create a temporary element to measure text height
      const temp = document.createElement('div');
      temp.style.width = '100%';
      temp.style.position = 'absolute';
      temp.style.visibility = 'hidden';
      temp.style.fontSize = fontSize + 'px';
      temp.style.lineHeight = lineHeight;
      temp.innerText = bio;
      document.body.appendChild(temp);
      
      const height = temp.offsetHeight;
      document.body.removeChild(temp);
      
      return height > maxHeight;
    },
    toggleBio(educator) {
      educator.showFullBio = !educator.showFullBio;
    }
  }
};
</script>

<style scoped>
.educators-page {
  padding: 80px 0;
  min-height: 100vh;
  background-color: #151515;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Page Header */
.page-header {
  margin-bottom: 40px;
  text-align: left;
  padding-top: 20px;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}

.educators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.educator-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.educator-card:hover {
  transform: translateY(-5px);
}

.educator-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.rounded-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.educator-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.educator-content {
  flex: 1;
}

.educator-name {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
}

.bio-container {
  position: relative;
  margin-bottom: 20px;
}

.educator-bio {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
  font-size: 16px;
}

.educator-bio.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.show-more-btn {
  background: none;
  border: none;
  color: #eb9e34;
  cursor: pointer;
  padding: 5px 0;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.show-more-btn:hover {
  color: #d88f2a;
}

.view-videos-btn {
  display: inline-block;
  background: #eb9e34;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  transition: background 0.3s ease;
  text-align: center;
  margin-top: 20px;
}

.view-videos-btn:hover {
  background: #d88f2a;
}

@media (max-width: 768px) {
  .educators-page {
    padding: 40px 0;
  }

  .page-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .educators-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
}
</style>