<template>
  <div id="app">
    <Header v-if="this.$route.name !== 'Login'"></Header>
    <div class="main-content">
      <router-view :key="$route.fullPath" />
    </div>
    <Footer v-if="this.$route.name !== 'Login'"></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import "firebase/auth";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }

    // this.login();
  },
  methods: {
    // login() {
    //   const _this = this;
    //   firebase.auth().onAuthStateChanged(function(user) {
    //     if (user) {
    //       ///get claims
    //       firebase
    //         .auth()
    //         .currentUser.getIdTokenResult()
    //         .then((idTokenResult) => {
    //           console.log("CLAIMS:", idTokenResult);
    //           user.membershipLevel = idTokenResult.claims.membershipLevel;
    //           user.active = idTokenResult.claims.active;
    //           // if (idTokenResult.claims.active == true) {
    //           _this.$store.commit({
    //             type: "login",
    //             user: user,
    //           });
    //           // } else {
    //           //   _this.$store.commit({
    //           //     type: "logout",
    //           //   });
    //           //      _this.$router.push("/login");
    //           // }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //       console.log("******* Signed In", user);
    //     } else {
    //       _this.$router.push("/login").catch((err) => {});
    //       console.log("<<<<<< Signed Out");
    //     }
    //   });
    // },
  },
};
</script>

<style>
body {
  background-color: #151515;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

footer {
  background-color: #151515;
}
.djv-page-title {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  text-align: center;
  padding: 40px;
}
.mt {
  margin-top: 10px;
}
h6 {
  font-size: 12px;
}
.pointer {
  cursor: pointer;
}

.grow {
  transition: 1s ease;
}

.grow:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
  /* opacity: 0.4; */
}
.hover:hover{
  background-color: rgb(47, 47, 47);
}

/* header .navbar ul li.menu-item a {
  font-size: 11px;
} */

/* Global styles for form inputs and selects */
.b-form-input:not(.vault-search),
.b-form-select:not(.vault-search),
.form-control:not(.vault-search) {
  height: 38px !important;
  line-height: 1.5 !important;
  padding: 0.375rem 0.75rem !important;
}

/* Ensure custom select arrows align properly */
.custom-select {
  height: 38px !important;
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

/* Admin table styles */
.container-fluid .table {
  background-color: #1f1f1f;
  color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.container-fluid .table thead th {
  background-color: #2a2a2a;
  border-bottom: 2px solid #3a3a3a;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 15px;
}

.container-fluid .table tbody tr {
  border-bottom: 1px solid #2a2a2a;
}

.container-fluid .table tbody tr:hover {
  background-color: #2f2f2f !important;
}

.container-fluid .table td {
  padding: 12px 15px;
  vertical-align: middle;
  border-top: none;
}

/* Style for striped rows */
.container-fluid .table-striped tbody tr:nth-of-type(odd) {
  background-color: #1a1a1a;
}

/* Pagination styling */
.container-fluid .pagination {
  margin-top: 1rem;
}

.container-fluid .page-link {
  background-color: #2a2a2a;
  border-color: #3a3a3a;
  color: #ffffff;
}

.container-fluid .page-link:hover {
  background-color: #3a3a3a;
  border-color: #4a4a4a;
  color: #ffffff;
}

.container-fluid .page-item.active .page-link {
  background-color: var(--iq-primary);
  border-color: var(--iq-primary);
}

/* Search input styling */
.container-fluid .form-control {
  background-color: #2a2a2a;
  border-color: #3a3a3a;
  color: #ffffff;
}

.container-fluid .form-control:focus {
  background-color: #2a2a2a;
  border-color: var(--iq-primary);
  color: #ffffff;
  box-shadow: none;
}

.container-fluid .form-control::placeholder {
  color: #888888;
}
</style>
