<template>
  <div>

    <div class="row">
      <div class="col-md-12 pb-4">
        <span v-if="comments.length">{{comments.length}} Comments</span>
        <span v-else>No comments yet</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
       <!--  <div class="user-icon text-center float-left mr-4">
          <div style="margin-top: 12px">{{this.$store.state.currentUser.displayName.charAt(0)
    .toUpperCase()}}</div> 
        </div>-->
        <div
          class="float-left"
          style="width: 90%; margin-top: 0px; margin-left: 4px"
        >

          <b-form-textarea
            id="textarea"
            v-model="newComment"
            :placeholder="comments.length ? 'Add a comment...' : 'Be the first to comment'"
            rows="1"
            max-rows="1"
          ></b-form-textarea>
          <div
            v-if="newComment.length"
            @click="addNewComment()"
            class="btn btn-link float-right"
          >Comment</div>
          <!-- <div
            @click="cancelComment()"
            class="btn btn-link float-right"
          >Cancel</div> -->

        </div>

      </div>
    </div>

    <div
      class="row pt-4 "
      v-for="comment in comments"
      :key="comment.id"
    >
      <div class="col-sm-12 p-2 hover">
        <!-- <div class="user-icon text-center float-left mr-4">
          <div style="margin-top: 12px">{{comment.userName.charAt(0)
    .toUpperCase()}}</div>
        </div> -->
        <div
          class="float-left"
          style="width: 90%; margin-top: 0px; margin-left: 4px"
        >
          <span class="text-muted">{{comment.userName}} - {{getDate(comment.created)}}</span><br>
          <span v-html="comment.comment"></span>
          <br>
          <i
            v-if="!comment.likes.includes($store.state.currentUser.uid)"
            class="fa fa-thumbs-o-up grow pointer pr-1"
            @click="likeComment(comment.id,comment.likes)"
          ></i>
          <i
            v-if="comment.likes.includes($store.state.currentUser.uid)"
            class="fa fa-thumbs-up grow pointer pr-1"
          
            @click="unLikeComment(comment.id,comment.likes)"
          ></i>

          {{comment.likes.length}} 
          <i
            v-if="$store.state.currentUser.uid == comment.userId && !confirm"
            class="fa fa-trash text-danger float-right pointer grow"
            v-on:click="confirmDelete(comment.id)"
          ></i>

          <div
            v-if="confirm && commentToDelete == comment.id"
            class="float-right"
          > <span class="mr-4">Are you sure?</span> <span
              @click="cancelDelete(comment.id)"
              class="text-success mr-4 pointer"
            >No</span> <span
              @click="deleteComment(comment.id)"
              class="text-danger pointer"
            >Yes</span></div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { db } from '@/db'
let moment = require('moment'); 

export default {
  props: ["post", "postId"],
  data () {
    return {
      newComment: "",
      comments: [],
      confirm: false,
      commentToDelete: null,

    }
  },

  mounted () {
    this.$bind(
      "comments",
      db
        .collection("comments")
        .where("postId", "==", this.postId)
        .orderBy("created", "asc")

    ).then(function () { });
  },
  methods: {
    async addNewComment (comment) {
      let c = {}
      c.created = Date.now();
      c.postId = this.postId;
      c.userId = this.$store.state.currentUser.uid;
      c.userName = this.$store.state.currentUser.displayName;
      c.comment = this.newComment;
      c.likes = [];

      const res = await db.collection("comments").add(c);
      this.newComment = '';
    },

    getDate(timestamp){
// return new Date(timestamp).toDateString()
return moment(timestamp).fromNow()
    },

    confirmDelete (commentId) {
      this.confirm = true
      this.commentToDelete = commentId
    },
    cancelDelete () {
      this.confirm = false
      this.commentToDelete = null
    },
    likeComment (commentId, likes) {

      likes.push(this.$store.state.currentUser.uid)
      db.collection("comments")
        .doc(commentId)
        .update({
          likes: likes

        })

    },
    unLikeComment (commentId, likes) {
      let newLikes = likes.filter((l) => {
        return l !== this.$store.state.currentUser.uid
      })

      db.collection("comments")
        .doc(commentId)
        .update({
          likes: newLikes

        })

    },

    async deleteComment (commentId) {
      db.collection('comments')
        .doc(commentId)
        .delete()

      this.confirm = false;
      this.commentToDelete = null;
    },

  },
  // firestore: {
  //   comments: db.collection('comments').where('postId', '==', postid).orderBy("created", "asc"),
  // },
}
</script>

<style>
.user-icon {
  border: white thin solid;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #263244;
}

textarea.form-control {
  height: auto;
}

.form-control {
  height: 20px;
  line-height: 20px;
  background: transparent;
  border-bottom: 1px solid var(--iq-body-text);
  font-size: 14px;
  color: var(--iq-secondary);
}
</style>